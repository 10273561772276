import styled from "styled-components";
import bg from "../../images/backgrounds/lines.png"
import bgShip from "../../images/ship1.png"
import mainBg from "../../images/main-bg.png"

export const HeroPageStyled = styled.div`
  background-image: url('${mainBg}');
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
  
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    background-image: url('${bgShip}');
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
    height: 100%;
  }
  
  h1 {
    color: blue;
  }

  @media (max-width: 1439px) {
    &::before {
      background-size: 62%;
    }
  }

  @media (max-width: 1023px) {
    background-image: none;

    &::before {
      display: none;
    }
  }
`;
