import axios from "axios";
import { injectable } from "inversify";
import { action, makeObservable, observable } from "mobx";
import "reflect-metadata";
import {RootStore} from "./RootStore";
import {toast} from "react-toastify";

interface User {
    login: string,
    email: string,
    address?: string,
    id?: string
}

@injectable()
export class UserStore {
    @observable user?: User;
    @observable isAuth: boolean;
    @observable isError: boolean;

    public constructor(private readonly rootStore: RootStore) {
        makeObservable(this);
        this.isAuth = false;
        this.isError = false;
    }

    async Login(sign, msg) {

    }

    async Disconnect(){

    }
    //get user data
    async GetUser() {

    }
}