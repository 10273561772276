import React from 'react';
import {UpcomingGameStyled} from "./UpcomingGame.styled";
import stakingImg from '../../images/staking.svg';
import transactionImg from '../../images/fast-transactions.svg';
import madnessImg from '../../images/madness.svg';
import multiplayerImg from '../../images/game.svg';
import battlegroundsImg from '../../images/battlegrounds.svg'
import bgCircleImg from '../../images/background_circle_minting.png';
import scoreboardImg from '../../images/scoreboard.svg';
import redBgImg from '../../images/red_background.png';
import heartImg from '../../images/heart.svg';
import {Icon} from "../UIcomponents/Icons/icons";
import {icons} from "../UIcomponents/Icons/icons";

const UpcomingGame: React.FC = () => {
    return (
        <UpcomingGameStyled>
            <h2>Upcoming game
                <br/>
                <span className="pink">made with unity</span>
            </h2>
            <div className="overview-minting">
                <div className="minting-item minting-item--fractions">
                    <div className="logo-background">
                        <Icon classname="scoreboard" icon="gamepads" />
                    </div>
                    <h3>Fractions</h3>
                    <p>
                        Support one of in-game fractions of Liminals. Build your reputations and complete various quests to become stronger
                    </p>
                </div>
                <div className="minting-item minting-item--merging">
                    <div className="logo-background">
                        <Icon classname="scoreboard" icon="lightning" />
                    </div>
                    <h3>Merging</h3>
                    <p>
                        Buy and sell shiny weapons and engines for your NFT-ship on the LIMINAL SPACE marketplace. Equip the best ones for deep space travel.
                    </p>
                </div>
                <div className="minting-item minting-item--staking">
                    <div className="logo-background">
                        <Icon classname="scoreboard" icon="dollar" />
                    </div>
                    <h3>Staking</h3>
                    <p>
                        Conquer resource-rich areas and hubs of economic activity to stake tokens.
                    </p>
                </div>
                <div className="minting-item minting-item--battlegrounds">
                    <div className="logo-background">
                        <Icon classname="scoreboard" icon="swords" />
                    </div>
                    <h3>Battlegrounds</h3>
                    <p>Fight pirates and other players in order to  take control of tactically important areas.
                    </p>
                </div>
                <div className="minting-item minting-item--madness">
                    <div className="logo-background">
                        {/* <img src={madnessImg}/> */}
                        <Icon classname="scoreboard" icon="spiral" />
                    </div>
                    <h3>Space madness</h3>
                    <p>
                        Discover a list of NFT games that enable you to earn crypto by winning tournaments or selling your in-game NFTs
                    </p>
                </div>
                <div className="minting-item minting-item--world">
                    <div className="logo-background">
                        <Icon classname="scoreboard" icon="gamepads" />
                        {/* <img src={heartImg} /> */}
                    </div>
                    <h3>Massive world</h3>
                    <p>
                        Immerse and expand into space and be rewarded for your effort. Nobody knows, what you can face in the liminal depths.
                    </p>
                </div>
            </div>
        </UpcomingGameStyled>
    );
};

export default UpcomingGame;
