import styled from "styled-components";
import bg from "../../images/backgrounds/mint.png"
export const MintStyled = styled.main`
  margin-top: 0px;
  /* margin-bottom: 100px; */
  background-color:#23232F;
  background-size: cover;
  min-height: calc(100vh);
  .bg-blue img {
    margin-left: 10px;
    height: 15px;
  }
  
  .main-content {
    box-sizing: border-box;
    margin: 12px;
    width: 100%;
    margin-top: 120px;

    @media (min-width: 768px) {
      
    }
    
    @media (min-width: 1024px) {
      margin: 0;
      width: calc(100% - 250px);
      margin-left: auto;
      padding: 0 40px;
    }
  }

  .connect-wallet-btn {
    margin-bottom: 70px;
  }
  
  .products-list {
    margin-bottom: 30px;

    @media (min-width: 768px) {

    }

    @media (min-width: 1024px) {
      
    }
  }
  
  .pagination {
    justify-content: center;
  }
`;
