import React, {useEffect} from 'react';
import {generatePath, Route, useHistory} from 'react-router-dom';
import {useInjection} from "inversify-react";
import Web3Store from "../../stores/Web3Store";
import LoadWrapper from "../LoadWrapper/LoadWrapper";
import {observer} from "mobx-react";
import {ModalStore, WalletStore} from "../../stores";
import MarketplacePage from "../../pages/MarketplacePage/MarketplacePage";
import AppStore from "../../stores/AppStore";
import {ModalsEnum} from "../../stores/ModalStore";

const PrivateRoute = observer(({render, path, exact}) => {
    const web3Store = useInjection(Web3Store);
    const walletStore = useInjection(WalletStore);
    const appStore = useInjection(AppStore);
    const modalStore = useInjection(ModalStore);
    const history = useHistory();

    useEffect(() => {
        if (appStore?.activePage === '/mynft' && !walletStore?.connected) {
            modalStore.showModal(ModalsEnum.Connect);
            history.push(generatePath('/marketplace'))
        }
    }, [appStore?.activePage, walletStore?.connected])

    return (
        <LoadWrapper isDataLoaded={!!web3Store?.authType}>
            <Route
                path={path}
                exact={exact}
                render={(routeProps) => (
                    walletStore?.connected
                        ? render(routeProps)
                        : <MarketplacePage isMyNft={false}/>
                )}
            />
        </LoadWrapper>
    );
});

export default PrivateRoute;
