import styled from "styled-components";
import bg from "../../images/backgrounds/bgTeam.png"
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'

export const TeamStyled = styled.div`
  //width: calc(100%);
  //height: auto;
  //float: left;
  position: relative;
  // z-index: 1;
  // background-image: url('${bg}');
  // /* left: 270px; */
  // background-size: cover;
  text-align: center;
  max-width: 1440px;
  padding: 80px 40px 0 40px;
  margin: 0 auto;
  
  &::before {
    position: absolute;
    content: '';
    top: 25px;
    right: 271px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: top right;
    width: 74px;
    height: 74px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 88px;
    right: 158px;
    background-image: url(${starSmall});
    background-repeat: no-repeat;
    background-position: top right;
    width: 54px;
    height: 46px;
  }
  
  .team__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 49px;
  }
  
  h2 {
    /* text-align: left; */
    font-size: 52px;
    color: white;
    text-transform: uppercase;
    position: relative;
    width: fit-content;
  }
  
  p {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    max-width: 680px;
    margin: 0 auto 20px;
  }
  
  .blue {
    background: linear-gradient(90deg, #3F3CD7 9.56%, #6D73F3 109.69%, #6171FE 193.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5)
  }
  
  .join-us {
    font-family: 'Dosis', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 124px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 15px 15px 15px 25px;
    width: 108px;
    height: 50px;
    background: linear-gradient(87.46deg, #3C4BD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);
    border-radius: 10px
  }
  //.green {
  //  color: #63FCA3;
  //  text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
  //}
  //
  //.robotic {
  //  font-family: 'Press Start 2P', cursive !important;
  //  font-size: 40px !important;
  //  line-height: 55px !important;
  //  display: inline-block;
  //  margin-left: 5px;
  //}
  //
  //.team-overview {
  //  width: 96%;
  //  margin-left: 4%;
  //  margin-top: 40px;
  //  height: auto;
  //  float: left;
  //
  //  .circle {
  //    width: 162px;
  //    height: 162px;
  //    border-radius: 50%;
  //    overflow: hidden;
  //    position: absolute;
  //    z-index: 10;
  //    top: 79px;
  //    left: 0;
  //    right: 0;
  //    margin-left: auto;
  //    margin-right: auto;
  //
  //    img {
  //      width: 100%;
  //      position: absolute;
  //      left: 50%;
  //      top: 50%;
  //      border-radius: 100%;
  //      transform: translate(-50%, -50%);
  //    }
  //  }
  //
  //  .team-item {
  //    width: 33%;
  //    height: 350px;
  //    float: left;
  //    position: relative;
  //    text-align: center;
  //    margin-bottom: 15px;
  //
  //    .bg-image {
  //      position: absolute;
  //      width: 190px;
  //      left: 0;
  //      right: 0;
  //      margin-left: auto;
  //      margin-right: auto;
  //      top: 65px;
  //    }
  //
  //    .title {
  //      font-family: Dosis;
  //      font-style: normal;
  //      font-weight: bold;
  //      font-size: 18px;
  //      line-height: 25px;
  //      text-align: center;
  //      text-transform: uppercase;
  //      // color: linear-gradient(180deg, #2F2F42 0%, #717185 100%);
  //      color: #37374b;
  //      position: absolute;
  //      left: 0;
  //      bottom: 40px;
  //      width: 100%;
  //    }
  //
  //    .name {
  //      font-family: 'Dosis', serif;
  //      font-style: normal;
  //      font-weight: 800;
  //      font-size: 21px;
  //      text-align: center;
  //      text-transform: uppercase;
  //      color: #FFFFFF;
  //      margin: 0 auto;
  //      width: 100%;
  //      float: left;
  //      position: absolute;
  //      left: 0;
  //      bottom: 70px;
  //    }
  //
  //    .team-item-info {
  //      width: 100%;
  //      height: auto;
  //      float: left;
  //    }
  //
  //    .socials {
  //      width: 100%;
  //      height: auto;
  //      position: absolute;
  //      bottom: 10px;
  //
  //      .linkedin {
  //        width: 25px;
  //        height: 25px;
  //        margin: 0 auto;
  //        display: block;
  //
  //        img {
  //          width: 100%;
  //        }
  //      }
  //    }
  //  }
  //}
  //}

  @media (max-width: 1439px) {
    padding: 143px 40px 0 40px;
  
    &::before {
      width: 53px;
      height: 53px;
      top: 22px;
      right: 194px;
      background-size: contain;
    }
  
    &::after {
      width: 38px;
      height: 33px;
      background-size: contain;
      top: 67px;
      right: 113px;
    }
  
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  
    p {
      max-width: 645px;
      letter-spacing: unset;
    }
  }

  @media only screen and (max-width: 1023px) {
    padding: 40px 16px;
    width: 100%;
    left: 0;
    
    &::before,
    &::after {
      display: none;
    }

  //.team-overview {
  //  width: 100%;
  //  margin-left: 0;
  //}

    h2 {
      text-align: center;
      font-size: 32px;
      line-height: 40px;
    }
  }

  //@media only screen and (max-width: 800px) {
  //  //.team-item {
  //  //  width: 100% !important;
  //  //}
  //  //.team-overview {
  //  //  height: auto !important;
  //  //}
  //  //
  //  //.team-overview {
  //  //  margin-left: 0px;
  //  //}
  //}
`;
