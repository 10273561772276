import { useState } from "react"
import cart from "../../images/svg//cart.svg"
type Props = {
    onClick: (event?: React.MouseEvent) => Promise<unknown>
    disabled?: boolean
    className?: string
    textClassName?: string
    text: string
}

export const NftButton: React.FC<Props> = ({ onClick, disabled, className, text, textClassName}) => {
    const [loading, setIsLoading] = useState(false)
    return (
        <button
            className={className}
            onClick={async (e) => {
                setIsLoading(true)
                e.stopPropagation()
                await onClick()
                    .then(() => setIsLoading(false))
                    .catch(() => setIsLoading(false))
            }}
            disabled={disabled || loading}
        >
            <span className={textClassName}>{loading ? 'Pending...' : text}</span>
            <img src={cart} style={{marginLeft:'10px'}}></img>
        </button>
    )
}