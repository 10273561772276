import React from 'react';
import {MintStyled} from "./MintLive.styled";
import ship3 from '../../images/ship1.png'
import arrow from '../../images/arrow.svg'
import AnimatedImg from "../AnimatedImg/AnimatedImg";

const MintLive: React.FC = ({setOpacity}:any) => {
    return (
        <MintStyled id="marketplaceBlock">
            <div className="explore">
                <div className="left">
                    <h2>mint is<span className="blue">&nbsp;coming soon </span>
                    </h2>
                    <p>
                        first wave of founders collectible NFTs in June,
                         raising $3 million. And now it will create NFT
                         collectibles in partnership with the NRG Esports
                          to tap into the competitive gaming market (which
                           NFTs and blockchain haven’t turned to yet)
                           and bolster fan engagement.
                    </p>
                    <div className="buttons">
                        <button className="button bg-blue">{"mint".toUpperCase()}<img src={arrow}/></button>

                    </div>
                </div>

            </div>
        </MintStyled>
    );
};

export default MintLive;
