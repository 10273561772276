import styled from "styled-components";

export const EmptyMarketplaceStyled = styled.div`
  margin-bottom: 140px;

  .modalShow {
    display: block !important;
  }

  .grey-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 555;
    background: rgba(0, 0, 0, 0.25);
    display: block;

    .white-overlay {
      width: 50%;
      height: auto;
      background: #fff;
      border-radius: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5555;
      padding: 25px;

      .cross {
        position: absolute;
        right: 5px;
        top: -10px;
      }

      .cross:after {
        cursor: pointer;
        font-size: 43px;
        display: inline-block;
        content: "\\00d7"; /* This will render the 'X' */
      }
    }
  }

  .explore {
    @media (max-width: 767px) {
      padding: 0 40px;
    }

    width: calc(100% - 20px);
    position: relative;
    z-index: 1;
    left: -30px;
    top: 10px;
    display: grid;
    grid-template-columns: 52% 55%;

    button {
      cursor: pointer;
    }

    .left {
      width: 100%;
      margin: auto;
    }
    .ship-img {
      width: 100%;
      max-width: 375px;
      max-height: 514px;
    }
    .image-container {
      position: relative;
      width: 432px;
      height: 432px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto auto auto 0;

      .image {
        position: relative;
        z-index: 2;
        margin: auto;
      }

      &:before {
        content: '';
        position: absolute;
        margin: auto;
        width: 336px;
        height: 336px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
      }
      
      &:after {
        position: absolute;
        content: '';
        width: 432px;
        height: 432px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        opacity: 0.5;
        filter: blur(200px);
        top: -20px;
        left: -20px;
        animation: lightup infinite;
        animation-duration: 4s;
      }
    }

    .left,
    .right {
      height: auto;
      text-align: left;

      .circle-background {
        position: absolute;
        top: 230px;
        right: 10px;
        width: 550px;
      }

      .karakter {
        width: 300px;
        margin-right: -20px;
        position: absolute;
        z-index: 55;
        top: 200px;
        right: 150px;
      }

      button,
      a {
        display: block;
        text-decoration: none;
        float: left;
        width: 200px;
        height: 50px;
        border-radius: 10px;
        font-size: 17px;
        font-family: "Dosis", sans-serif !important;
        text-align: center;
        line-height: 50px;
        color: #fff;
        border: 0;
        margin-top: 18px;
        font-weight: 800;
      }

      .bg-blue {
        background: linear-gradient(87.46deg, #3C4BD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);
        margin-left: 40px;
      }

      .bg-pink {
        background: linear-gradient(264.06deg, #FF0000 -68.99%, #D174E0 40.01%, #FF7070 130.88%);
        margin-left: 20px;
      }

      h2 {
        font-size: 52px;
        line-height: 65px;
        font-weight: 800;
        color: #fff;
        text-transform: uppercase;
        margin-left: 40px;
        font-family: "Dosis", sans-serif !important;
      }

      .green {
        display: inline-block;
        margin-left: -25px;
        color: #63FCA3;
        text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
      }
      .blue {
        color: #6372FC;
        text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5);
      }
      p {
        margin-top: 20px;
        font-style: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 152.4%;
        color: #fff;
        margin-left: 40px;
        font-family: "Dosis", sans-serif !important;
        width: 70%;
      }
    }

    .circle-holder {
      width: auto;
      height: auto;
      margin-top: 220px;
      margin-left: 80px;
      position: relative;

      .big-circle {
        width: 336px;
        height: 336px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        position: relative;
        z-index: 5;
      }

      .big-circle-fade {
        width: 432px;
        height: 432px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        opacity: 0.5;
        filter: blur(200px);
        position: absolute;
        top: -20px;
        left: -20px;
        animation: lightup infinite;
        animation-duration: 4s;
      }

      .circle-1 {
        position: absolute;
        top: -80px;
        margin-left: 220px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
      }

      .circle-2 {
        position: absolute;
        top: -20px;
        margin-left: 300px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        filter: blur(10px);
      }

      .circle-3 {
        position: absolute;
        top: 70px;
        margin-left: 360px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        filter: blur(15px);
      }

      img {
        top: -30px;
        left: 70px;
        position: absolute;
        z-index: 5;
      }
    }

    @keyframes lightup {
      0% {
        filter: blur(200px);
      }
      50% {
        filter: blur(100px);
        opacity: 0.8;
      }
      100% {
        filter: blur(200px);
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .explore {
      width: 98%;
      left: 0;
    }

    .explore .left {
      padding-top: 0;
    }
  }

  @media only screen and (max-width: 800px) {
    .white-overlay {
      width: 87% !important;
    }

    .explore {
      height: auto;
      grid-template-columns: 1fr;
    }

    .explore .left {
      width: 100%;
      margin-bottom: 35px;
      
      h2 {
        margin-left: 0;
        font-size: 35px;
        line-height: 45px;
        
        span {
          margin-left: -15px!important;
        }
      }
      
      p {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
      
      .buttons {
        display: flex;
        flex-direction: column;
      }
      
      .button {
        margin-left: 0!important;
      }
    }
    

    .explore .circle-holder {
      margin-top: 120px;
    }

    .explore .left {
      padding-top: 0px !important;
    }

    .explore .left .robotic {
      font-size: 30px !important;
      line-height: 46px !important;
    }
  }
  
  @media screen and (max-width: 560px) {
    .buttons {
      width: 100%;
    }
    
    .button {
      width: 100%!important;
    }
  }
`;
