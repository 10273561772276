import React from 'react';
import {ContentOneStyled} from "./ContentOne.styled";
import shipMobile from "../../images/ship-1-mobile.png";
import AnimatedImg from "../AnimatedImg/AnimatedImg";
import {Icon} from "../UIcomponents/Icons/icons";
import { Link } from 'react-router-dom';

const ContentOne: React.FC = ({setOpacity}:any) => {
    return (
        <ContentOneStyled>
            <div className="explore">
                <div className="left">
                    <h2 className="heading">EXPERIENCE THE BEST FEATURES <br className="break-desktop-tablet"/>
                        OUT OF<br className="break-mobile"/> <span className="blue">TWO WORLDS</span>
                    </h2>
                    <div className='ship-img-wrapper'>
                        <img className='ship-img' src={shipMobile} onLoad={()=>{setOpacity(true)}} />
                    </div>
                    <p>
                        Strategy game made with UNITY meets BLOCKCHAIN: drift through cold empty
                        space with your NFT-ship. Upgrade it, conquer planets and systems or just
                        stake to earn tokens.  Mint your ship soon to get access to alpha-version of the
                        game and contribute to the project.
                    </p>
                    <div className="buttons">
                        <Link to="/mint"><button className="button bg-blue">{"Mint info".toUpperCase()}<Icon classname="arrow-right" icon="triangleRight" /></button></Link>
                        <a className="button bg-pink" href="#roadmapBlock">{"Roadmap".toUpperCase()}</a>
                    </div>
                </div>
            </div>
        </ContentOneStyled>
    );
};

export default ContentOne;
