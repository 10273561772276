import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0008;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .modal_container {
    position: relative;
    padding: 40px;
    text-align: left;
    width: 483px;
    box-shadow: 0 4px 45px rgba(0, 0, 0, 0.25);
    min-width: 200px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    background: linear-gradient(90.6deg, #2E2E3C 55.82%, #23232F 98.55%);
  }
`;
