import styled from "styled-components";
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'

export const MintMarketStyled = styled.div`
  margin-bottom: 120px;
  padding-top: 28px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 143px;
    left: 154px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: top left;
    width: 74px;
    height: 74px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 78px;
    left: 41px;
    background-image: url(${starSmall});
    background-repeat: no-repeat;
    background-position: top left;
    width: 54px;
    height: 46px;
  }
  
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .row_item {
    min-width: 298px;
    margin-right: 20px;
    margin-top: 20px;
    pointer-events: none;
  }
  .text {
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.035em;
    margin-bottom: 63px;
  }
  
  p {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    max-width: 774px;
    text-align: center;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
    
    img {
      margin-left: 45px;
    }
    @media (max-width: 700px) {
      flex-direction : column;
      img {
        margin-left: 10px;
        margin-top: 20px
      }
    }
  }
  h2 {
    text-align: center;
    font-size: 52px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: "Dosis", sans-serif !important;
  }
  .blue {
    color: #6372fc;
    text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5);
  }
`;
