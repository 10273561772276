import React from 'react';
import {RarityStyled} from "./Rarity.styled";

interface Props {
    rarity?: number,
    isMobile?: boolean,
}


const Rarity: React.FC<Props> = ({rarity = 1, isMobile}: Props) => {

    return (
        <RarityStyled className="rarity" isMobile={isMobile}>
            {!isMobile && <p className="rarity-title section-title">Rarity</p>}
            <div className={`rarity-levels rarity-${rarity}`}>
                <div className="rarity-level">
                    <span className="visually-hidden">1</span>
                </div>
                <div className="rarity-level">
                    <span className="visually-hidden">2</span>
                </div>
                <div className="rarity-level">
                    <span className="visually-hidden">3</span>
                </div>
                <div className="rarity-level">
                    <span className="visually-hidden">4</span>
                </div>
                <div className="rarity-level">
                    <span className="visually-hidden">5</span>
                </div>
            </div>
        </RarityStyled>
    );
};

export default Rarity;
