import React, { Component } from "react";
import Slider from "react-slick";
import ship from "../../images/ship.png";
import {Icon} from "../UIcomponents/Icons/icons";
import detail from "../../images/detail.png";
import secret from "../../images/secret.png";
import {SliderTypesStyled} from "./SliderTypes.styled";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

export default () => {
    return (
        <SliderTypesStyled>
            <Swiper className="slider-types__list"
                spaceBetween={30}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                observer={true}
                observeParents={true}
                    watchOverflow={true}

                    breakpoints={
                        {
                            // when window width is >= 320px
                            320: {
                                centeredSlides: false,
                                slidesPerView: "auto",
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 3
                            }
                        }
                    }
            >
                    <SwiperSlide className="slider-types__item">
                        <div >
                                <span>Phase 1</span>
                                <div className="slider-types__card slider-types__card--spaceships">
                                    <img src={ship}/>
                                    <h3>Spaceships</h3>
                                    <p>Main collection with uniq 5000 spaceships</p>
                                    <a href="#marketplaceBlock">Read more<Icon classname="arrow-right" icon="arrowRight" /></a>
                                </div>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider-types__item">
                        <div >
                                <span>Phase 2</span>
                                <div className="slider-types__card slider-types__card--details">
                                    <img src={detail}/>
                                    <h3>Details</h3>
                                    <p>Upgrade your spaceships</p>
                                    <a>Coming soon...</a>
                                </div>
                            </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider-types__item">
                        <div >
                                <span>Season 3</span>
                                <div className="slider-types__card slider-types__card--secret">
                                    <img src={secret}/>
                                    <h3>Secret type</h3>
                                    <p>Most secret part</p>
                                    <a>Coming soon...</a>
                                </div>
                            </div>
                    </SwiperSlide>
            </Swiper>
        </SliderTypesStyled>
    );
}

// export default class SliderTypes extends Component {
//     render() {
//         const settings = {
//             className: "center",
//             infinite: true,
//             centerPadding: "60px",
//             centerMode: true,
//             slidesToShow: 3,
//             swipeToSlide: true,
//             arrows: false,
//             initialSlide: 0,
//             responsive: [
//                 {
//                     breakpoint: 1024,
//                     settings: {
//                         slidesToShow: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 600,
//                     settings: {
//                         slidesToShow: 2,
//                     }
//                 },
//                 {
//                     breakpoint: 480,
//                     settings: {
//                         slidesToShow: 1,
//                     }
//                 }
//             ],
//             afterChange: function(index) {
//                 console.log(
//                     `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
//                 );
//             }
//         };
//         return (
//             <SliderTypesStyled>
//             <div className="slider-types__list">
//                 <Slider {...settings}>
//                     <div className="slider-types__item">
//                             <span>Phase 1</span>
//                             <div className="slider-types__card slider-types__card--spaceships">
//                                 <img src={ship}/>
//                                 <h3>Spaceships</h3>
//                                 <p>Main collection with uniq 5000 spaceships</p>
//                                 <a href="#marketplaceBlock">Read more<Icon classname="arrow-right" icon="arrowRight" /></a>
//                             </div>
//                         </div>
//                     <div className="slider-types__item">
//                             <span>Phase 2</span>
//                             <div className="slider-types__card slider-types__card--details">
//                                 <img src={detail}/>
//                                 <h3>Details</h3>
//                                 <p>Upgrade your spaceships</p>
//                                 <a>Coming soon...</a>
//                             </div>
//                         </div>
//                     <div className="slider-types__item">
//                             <span>Season 3</span>
//                             <div className="slider-types__card slider-types__card--secret">
//                                 <img src={secret}/>
//                                 <h3>Secret type</h3>
//                                 <p>Most secret part</p>
//                                 <a>Coming soon...</a>
//                             </div>
//                         </div>
//                 </Slider>
//             </div>
//             </SliderTypesStyled>
//         );
//     }
// }
