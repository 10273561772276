import React, {useEffect} from 'react';
import {SuccessSellModalStyled} from "./SuccessSellModal.styled";
import {useInjection} from "inversify-react";
import AppStore from "../../stores/AppStore";
import {observer} from "mobx-react";

const SuccessSellModal: React.FC = observer(() => {
    const appStore = useInjection(AppStore);

    return (
        <SuccessSellModalStyled className={(appStore?.isSuccessSellModalShown ? '' : 'hidden')}>
            <div className="modal-container">
                <h2 className="title">Sold <span className="title-label">successfully</span></h2>
                <p className="text">
                    Your token was sold successfully.
                </p>
                <button
                    className="confirm-btn"
                    onClick={() => appStore.changeSuccessSellModalView(false)}
                >
                    <span className="confirm-btn-text">Okay</span>
                </button>
                <button
                    className="close-btn"
                    onClick={() => appStore.changeSuccessSellModalView(false)}
                >
                    <span className="visually-hidden">Close modal</span>
                </button>
            </div>
        </SuccessSellModalStyled>
    );
});

export default SuccessSellModal;
