import styled from "styled-components";
import mintingBg from "../../images/backgrounds/mintingBg.png"
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'

export const UpcomingGameStyled = styled.div`
  /* width: calc(100% - 270px); */
  position: relative;
  // background-image: url('${mintingBg}');
  // background-size: contain;
  // background-position: top right;
  // background-repeat: no-repeat;
  z-index: 1;
  text-align: left;
  margin-bottom: 120px;
  padding-top: 200px;
  background-image: url(${starSmall});
  background-repeat: no-repeat;
  background-position: 91px 28px;

  &::before {
    position: absolute;
    content: '';
    top: 192px;
    right: 140px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: top right;
    width: 74px;
    height: 74px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 618px;
    left: 71px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: top right;
    width: 74px;
    height: 74px;
  }
  
  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    margin-left: 8vw ;
    font-family: 'Dosis', sans-serif !important;
    margin-bottom: 75px;
  }

  .pink {
    background: linear-gradient(264.06deg, #FF0000 -68.99%, #D174E0 40.01%, #FF7070 130.88%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
/* text-fill-color: transparent; */
    text-shadow: 0px 4px 25px #EB72A1;
  }

  .robotic {
    font-family: 'Press Start 2P', cursive !important;
    font-size: 40px !important;
    line-height: 55px !important;
    display: inline-block;
    margin-left: 5px;
  }

  .scoreboard {
    /* width: 67px; */

  }

  .minting-item:hover .scoreboard {
    filter: drop-shadow(0 0 25px #D174E0);
    transition-duration: .3s;
  }
  
  .minting-item--fractions:hover .circle {
    fill:url(#gamepads);
    stroke: url(#gamepads);
  }

  .minting-item--merging:hover .circle {
    fill:url(#lightning);
    stroke: url(#lightning);
  }

  .minting-item--staking:hover .circle {
    fill:url(#dollar);
    stroke: url(#lightning);
  }

  .minting-item--battlegrounds:hover .circle {
    fill:url(#swords);
    stroke: url(#lightning);
  }

  .minting-item--madness:hover .circle {
    fill:url(#spiral);
    stroke: url(#lightning);
  }

  .minting-item--world:hover .circle {
    fill:url(#gamepads);
    stroke: transparent;
  }

  .overview-minting {
    width: 80%;
    margin: 0 auto;
    /* margin-left: 40px; */
    height: auto;
    //float: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 81px 61px;
    padding-left: 60px;
    /* margin-top: 40px; */

    .minting-item {
      width: 100%;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: max-content 1fr;
      grid-column-gap: 24px;

      .logo-background {
        width: 150px;
        height: 150px;
        background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
        box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
        border-radius: 34px;
        position: relative;
        float: left;
        grid-row: 1/-1;
        display: flex;
        justify-content: center;
        align-items: center;

        .image-icon {
          position: absolute;
          width: 70px;
          top: 59px;
          left: 38px;
          filter: brightness(0) invert(1);
        }

        img {
          position: absolute;
          top: -23px;
          left: -33px;
        }
      }

      h3 {
        float: left;
        font-family: Dosis,serif;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        text-transform: uppercase;
        color: #fff;
        margin-top: 0;
        margin-bottom: 7px;
      }

      p {
        color: #fff;
        font-family: Dosis,serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        width: 82%;
      }
    }
  }
}

@media screen and (max-width: 1439px) {
  background-size: 38px 33px;
  padding-top: 84px;
  
  &::before {
    background-size: contain;
    width: 52px;
    height: 52px;
    top: 48px;
    right: 100px;  
  }
  
  &::after {
    background-size: contain;
    width: 52px;
    height: 52px;
    top: 351px;
    left: 51px;
  }
  
  h2 {
    font-size: 32px;
    line-height: 40px;
    margin-left: 4vw;
  }
  
  .overview-minting {
    grid-gap: 62px 42px;
    width: 97%;
    
    .minting-item {
      .logo-background {
        width: 124px;
        height: 124px;
      }
      
      .scoreboard {
        width: 73px;
        height: 73px;
      }

      h3 {
        font-size: 25px;
        line-height: 32px;
      }
    }
  }
  
  .minting-item p {
    width: 80%;
  }
}

@media only screen and (max-width: 1023px) {
  width: 100%;
  left: 0;
  background-image: none;
  padding: 59px 13px 30px 17px;
  margin-bottom: 43px;
  
  &::before,
  &::after {
    display: none;
  }

  .overview-minting {
    grid-template-columns: 1fr;
    grid-gap: 19px;
    padding: 0;
  }
  
  .minting-item--staking {
    grid-row: 2 / 3;
  }

  .minting-item--madness {
    grid-row: 3 / 4;
  }
  
  .overview-minting .minting-item p {
    font-size: 12px;
    line-height: 18px;
    width: unset;
  }
  
  h2 {
    margin-bottom: 39px;
  }

  .overview-minting .minting-item .logo-background {
    width: 111px;
    height: 111px;
    border-radius: 26px;
  }
}

@media only screen and (max-width: 800px) {
  height: auto;

  .overview-minting .minting-item {
    width: 100%;
    height: auto;
  }
  
  .robotic {
    font-size: 30px;
    line-height: 46px;
  }

  h2 {
    margin-left: 20px;
  }

  @media only screen and (max-width: 600px) {
    .logo-background {
      width: 100px;
      height: 100px;
      
      img:not(.image-icon):not(.scoreboard) {
        width: 150px;
        height: 150px;
        top: -19px;
        left: -25px;
      }
      
      .image-icon {
        top: 38px;
        left: 25px;
        width: 50px;
      }
      
      .scoreboard {
        top: 33px;
        left: 31px;
        width: 37px;
      }
    }
    
    h2 {
      margin-left: 0;
    }
    
    .overview-minting {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
`;
