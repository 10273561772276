const doBackendURL = 'http://do.bykollab.com:14035/';
const DO_HTTPS_BACKEND_URL = 'https://bithotel-back.do.bykollab.com/';

export const apiUrl = window.location.hostname ==  'localhost' ? doBackendURL : DO_HTTPS_BACKEND_URL;
// export const contracts = {
//     nft: {
//         abiUrl: '/abi/nft_abi.json',
//         address: {
//             ropsten: '0x73683A43f4c33acaF752C480b334be4bd92150b7',
//             mainnet: '0x73683A43f4c33acaF752C480b334be4bd92150b7',
//         },
//     },
//     market: {
//         abiUrl: '/abi/market_abi.json',
//         address: {
//             ropsten: '0x3a1c16be623e6BF43A42aC04f40414316aEDc022',
//             mainnet: '0x3a1c16be623e6BF43A42aC04f40414316aEDc022',
//         },
//     },
// }
