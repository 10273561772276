import React, {useRef} from 'react';
import {RoadmapStyled} from "./Roadmap.styled";
import planetFirst from "../../images/planet-first.png"
import planetSecond from "../../images/planet-second.png"
import ship1 from "../../images/ship1.png";

interface roadPage {
    title?: boolean
}
const Roadmap = ({title}:roadPage) => {
    return (
        <RoadmapStyled id="roadmapBlock">
            <h2 >Our <span className="blue">roadmap</span></h2>
            <div className="roadmap" id="roadmap">
                <ul className="roadmap__list">
                    <li className="roadmap__item roadmap__item--done">
                        <span>Landing launch</span>
                        <p>With roadmap and game information</p>
                    </li>
                    <li className="roadmap__item roadmap__item--upcoming">
                        <span>NFT  sale</span>
                        <p>A collection of  5000 uniq spaceships</p>
                    </li>
                    <li className="roadmap__item roadmap__item--upcoming">
                        <span>Token TGE</span>
                        <p>More info coming soon</p>
                    </li>
                    <li className="roadmap__item roadmap__item--upcoming">
                        <span>Marketplace launch</span>
                        <p>Buy and sell new spaceships</p>
                    </li>
                    <li className="roadmap__item roadmap__item--upcoming">
                        <span>Staking</span>
                        <p>Explore space and earn money</p>
                    </li>
                    <li className="roadmap__item roadmap__item--upcoming">
                        <span>Game DEMO for holders</span>
                        <p>Closed alfa of new game</p>
                    </li>
                </ul>
                <ul className="roadmap__planets planets__list">
                    <li className="planets__item planets__item--first">
                        <p>Phase 1</p>
                        <img className="planets-img" src={planetFirst} />
                    </li>
                    <li className="planets__item planets__item--second">
                        <p>Phase 2</p><span className="soon">Soon</span>
                        <img className="planets-img" src={planetSecond} />
                    </li>
                </ul>
                {/*<div className="content-field">*/}
                {/*    <div className="v13 horizontal"/>*/}
                {/*    <div className="v14 horizontal"/>*/}
                {/*    <div className="v15 horizontal"/>*/}
                {/*    <div className="dot1 dot"/>*/}
                {/*    <div className="dot2 dot"/>*/}
                {/*    <div className="dot3 dot"/>*/}
                {/*    <div className="dot4 dot"/>*/}
                {/*    <div className="dot5 dot"/>*/}
                {/*    <div className="line1 c-line"/>*/}
                {/*    <div className="line2 c-line"/>*/}
                {/*    <div className="line3 c-line"/>*/}
                {/*    <div className="line4 c-line"/>*/}
                {/*    <div className="line5 c-line"/>*/}
                {/*    <div className="center">*/}
                {/*        <div className="box1 box">*/}
                {/*            <span className="roadmap-title">2021 Q1</span>*/}
                {/*            <ul>*/}
                {/*                <li>Solving issues</li>*/}
                {/*                <li>New mini games</li>*/}
                {/*                <li>Staking</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        <div className="box2 box">*/}
                {/*            <span className="roadmap-title">2021 Q2</span>*/}
                {/*            <ul>*/}
                {/*            <li>Solving issues</li>*/}
                {/*                <li>New mini games</li>*/}
                {/*                <li>Staking</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        <div className="box3 box">*/}
                {/*            <span className="roadmap-title">2021 Q3</span>*/}
                {/*            <ul>*/}
                {/*                <li>Solving issues</li>*/}
                {/*                <li>New mini games</li>*/}
                {/*                <li>Staking</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        <div className="box4 box">*/}
                {/*            <span className="roadmap-title">2021 Q4</span>*/}
                {/*            <ul>*/}
                {/*                <li>Solving issues</li>*/}
                {/*                <li>New mini games</li>*/}
                {/*                <li>Staking</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*        <div className="box5 box">*/}
                {/*            <span className="roadmap-title">2021 Q5</span>*/}
                {/*            <ul>*/}
                {/*                <li>Game Launch</li>*/}
                {/*                <li>Monthly Game Updates</li>*/}
                {/*            </ul>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </RoadmapStyled>
    );
};

export default Roadmap;
