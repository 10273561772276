import React from 'react';
import {resolve} from "inversify-react";
import {ModalStore, WalletStore} from "../../stores";
import {ModalsEnum} from "../../stores/ModalStore";
import {observer} from "mobx-react";
import Modal from '../Modal/Modal';
import {ConnectWalletStyled} from "./ConnectWallet.styled";
import arrowImg from "../../images/svg/arrow-right.svg";

interface IConnectWalletProps {
    data: { stop: boolean };
    idx: number;
}

interface IConnectModalState {
}

@observer
class ConnectWalletComponent extends React.Component<IConnectWalletProps, IConnectModalState> {
    @resolve(WalletStore)
    declare protected readonly walletStore: WalletStore;
    @resolve(ModalStore)
    declare protected readonly modalStore: ModalStore;


    render() {
        return (
            <ConnectWalletStyled>
                <Modal modalKey={ModalsEnum.Connect} idx={this.props.idx}>
                    <h2 className="modal-title">Connect your
                        <span className="modal-title-green"> wallet</span>
                    </h2>
                    <p className="modal-description">To make use of our games and services, you have to connect/install your wallet.</p>
                    <div className="wallets">
                        <button className="wallet wallet-coinbase" onClick={() => this.walletStore.connectDefer.resolve('custom-walletlink')}>
                            Coinbase Wallet
                            <img className="follow-btn" height="24" width="24" src={arrowImg} alt=""/>
                        </button>
                        <button className="wallet wallet-metamask" onClick={() => this.walletStore.connectDefer.resolve('injected')}>
                            MetaMask
                            <img className="follow-btn" height="24" width="24" src={arrowImg} alt=""/>
                        </button>
                    </div>
                    <button className="close-btn" onClick={() => this.modalStore.hideModal(this.props.idx)}>
                        <span className="visually-hidden">Close modal</span>
                    </button>
                </Modal>
            </ConnectWalletStyled>
        )
    }
}

export const ConnectWallet = (ConnectWalletComponent);
