import React, {useEffect, useRef} from 'react';
import {PaginationStyled} from "./Pagination.styled";
import {useInjection} from "inversify-react";
import AppStore from "../../stores/AppStore";
import arrowSvg from "../../images/svg/arrow-right.svg";
import {observer} from "mobx-react";

interface Props {
    tokensLength: number,
    className?: string,
}

export const MAX_PER_PAGE = 16;

const Pagination: React.FC<Props> = observer(({className, tokensLength}: Props) => {
    const appStore = useInjection(AppStore)
    const pagesNumber = Math.ceil(tokensLength / MAX_PER_PAGE);
    const pages = [];

    for (let i = 0; i < pagesNumber; i++) {
        pages.push(i + 1);
    }

    const onItemClickHandler = (page) => {
        appStore.setMarketplacePage(page);
    }

    return (
        <PaginationStyled className={className}>
            <button
                className="prev nav-button"
                onClick={() => onItemClickHandler(appStore?.marketplacePage - 1)}
                disabled={appStore?.marketplacePage === 1}
            >
                <span className="visually-hidden">Previous</span>
                <img src={arrowSvg} alt=""/>
            </button>
            <ul className="list">
                {
                    pages.map((item, index) => {
                        return (
                            <li
                                className={`item ${item === appStore?.marketplacePage ? 'active' : ''}`}
                                key={index}
                                onClick={() => (onItemClickHandler(item))}
                            >
                                {item}
                            </li>
                        )
                    })
                }
            </ul>
            <button
                className="next nav-button"
                onClick={() => onItemClickHandler(appStore?.marketplacePage + 1)}
                disabled={appStore?.marketplacePage === pages.length}
            >
                <span className="visually-hidden">Next</span>
                <img src={arrowSvg} alt=""/>
            </button>
        </PaginationStyled>
    );
});

export default Pagination;
