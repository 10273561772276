import React, { useEffect, useState } from 'react';
import {HeroPageStyled} from "./HeroPage.styled";
import Navigation from "../../components/Navigation/Navigation";
import ConnectWalletBtn from "../../components/ConnectWalletBtn/ConnectWalletBtn";
import ContentTwo from "../../components/ContentTwo/ContentTwo";
import ContentOne from "../../components/ContentOne/ContentOne";
import Partners from "../../components/Partners/Partners";
import Minting from "../../components/Minting/Minting";
import TypesOfNFT from "../../components/TypesOfNFT/TypesOfNFT";
import Roadmap from "../../components/Roadmap/Roadmap";
import Team from "../../components/Team/Team";
import { appearance } from '../Mint/MintPage';
import UpcomingGame from "../../components/UpcomingGame/UpcomingGame";
import EmptyMarketplace from "../../components/EmptyMarketplace/EmptyMarketplace";
import MintMarket from "../../components/MintMarket/MintMarket";
import Footer from "../../components/Footer/Footer";

const HeroPage: React.FC = () => {
    const [opacity, setOpacity] = useState<boolean>(false)
    useEffect(()=>{
        // setOpacity(true)
    },[])
    return (
        <HeroPageStyled>
            {/* <Navigation/> */}
            <div className="container" style={appearance(opacity)}>
                <ConnectWalletBtn  page="Home"/>
                <ContentOne setOpacity={setOpacity}/>
                <ContentTwo/>
                {/*<Partners/>*/}
                {/*<Minting/>*/}
                <TypesOfNFT/>
                <UpcomingGame/>
                {/*<MintMarket/>*/}
                <Roadmap/>
                <Team/>
                <Footer/>
            </div>
        </HeroPageStyled>
    );
};

export default HeroPage;
