import styled from "styled-components";

export const SelectedProductStyled = styled.main`
  margin-top: 20px;
  margin-bottom: 60px;
  position: relative;

  .main-content {
    box-sizing: border-box;
    width: calc(100% - 250px);
    margin-left: auto;
    padding: 0 40px;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .nft {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: 6fr 2fr 4fr;
    position: relative;
    margin-bottom: 140px;
  }

  .nft-name {
    display: flex;
    align-items: center;
    margin: 0 auto auto;
  }

  .name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 800;
    margin-right: 30px;
  }

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    padding: 7px 12px 7px 18px;
    background-color: #454558;
    font-weight: 700;
    border-radius: 5px;
    line-height: 25px;
    display: flex;
    align-items: center;

    img {
      transform: rotate(180deg);
      margin-right: 5px;
    }
  }

  .icon-img {
    grid-column: 1/2;
    grid-row: 2/4;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    object-fit: contain;
  }

  .characteristics {
    grid-column: 2/4;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 50px;
    width: 100%;
    background: linear-gradient(180deg, #2E2E3C 55.66%, #23232F 125.89%);
    box-shadow: 0 4px 45px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 10px 100px 20px 30px;
    margin-bottom: 30px;

    .rarity {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .quantity {
    margin-left: 0;
  }

  .owner {
    display: flex;
    margin-bottom: auto;
    margin-top: 13px;
  }

  .owner-number {
    color: #9393A4;
  }

  .description {
    grid-column: 2/4;
    margin-bottom: 140px;
    padding-right: 230px;
  }

  .description-title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .rarity-title {
    color: #717185;
    margin-bottom: 14px;
    text-align: left;
  }

  .price {
    display: inline-block;
  }

  .quantity {
    display: inline-block;
  }

  .replicas {
    display: inline-block;
  }

  .rarity {
    display: inline-block;
  }

  .sell-button {
    grid-column: 2/3;
    z-index: 2;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primaryText};
    background: ${({theme}) => theme.colors.background} linear-gradient(358.84deg, #3CC4D7 -38.41%, rgba(109, 243, 211, 0.5) 17.27%, rgba(97, 254, 160, 0) 63.69%);
    border: 1px solid ${({theme}) => theme.colors.border};
    padding: 15px 15px 16px;
    border-radius: 10px;
    position: relative;
    margin-bottom: auto;
    margin-right: 20px;

    .sell-button-text {
      position: relative;
      margin-bottom: 0;
      z-index: 100;
    }

    &:hover {
      opacity: 0.5;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 12px);
      height: calc(100% - 5px);
      top: -1px;
      left: 6px;
      right: 6px;
      z-index: 3;
      background: linear-gradient(87.46deg, #3CC4D7 -3.04%, #6DF3D3 92.88%, #61FEA0 172.86%);
      border-radius: 10px;
    }
  }

  .sell-button-text {
    display: block;
    margin-top: -1px;
  }
  
  .sell-button:disabled {
    opacity: 0.3;
  }

  .h2 {
    font-size: 20px;
    line-height: 25px;
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .more-nft-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .history {
    margin-left: 40px;
  }

  .sale-history-list {
    padding: 30px 20px;
    background: linear-gradient(180deg, #2E2E3C 55.66%, #23232F 125.89%);
    box-shadow: 0 4px 45px rgba(0, 0, 0, 0.15);
    border-color: ${({theme}) => theme.colors.border}
    border-radius: 5px;
  }

  .sale-history-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    //justify-content: space-between;
    grid-column-gap: 15%;
    border-bottom: 1px solid #4d4d53;
    padding: 20px 5px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;

    &:last-child {
      border-bottom: none;
    }
  }

  .label {
    color: #9393A4;
    line-height: 24px;
    font-size: 16px;
    position: relative;

    &:before {
      content: '';
      height: 6px;
      width: 6px;
      margin-left: 5px;
      position: absolute;
      border-radius: 50%;
      left: -17px;
      top: 9px;
    }
  }

  .buyer-label {
    &:before {
      background: #63FCA3;
      box-shadow: 0 0 5px rgba(113, 218, 180, 0.5);
    }
  }

  .seller-label {
    &:before {
      background: #C63B3B;
      box-shadow: 0 0 5px rgba(218, 113, 113, 0.5);
    }
  }

  .sale-price {
    font-weight: 800;
    line-height: 24px;
  }

  .sale-date {
    color: #454558;
    font-weight: 800;
  }

  @media screen and (max-width: 1360px) {
    .container {
      grid-template-columns: 1fr;
    }
    
    .nft {
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px;
      grid-column-gap: 30px;
      margin-bottom: 50px;
    }
    
    .history {
      margin-left: 0;
    }

    .back-btn {
      display: none;
    }

    .nft-name {
      grid-column: 1/3;
    }

    .icon-img {
      grid-column: 1/2;
      grid-row: 2/6;
      width: 100%;
      object-fit: contain;
    }

    .characteristics {
      order: 2;
      grid-column: 1/3;
      padding-right: 30px;
    }

    .sell-button {
      order: 3;
      grid-row: 4/5;
      grid-column: 2/3;
    }

    .owner {
      grid-column: 2/3;
      grid-row: 3/4;
    }
    
    .description {
      grid-row: 2/3;
      margin-top: 60px;
      margin-bottom: 0;
      padding-right: 0;
    }

    .more-nft-list {
      margin-bottom: 40px;
    }
  }
  
  @media screen and (max-width: 1140px) {
    .owner {
      display: flex;
      flex-direction: column;
    }
    
    .owner-number {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    .main-content {
      width: 100%;
    }
  }

  @media screen and (max-width: 790px) {
    .nft {
      grid-template-columns: 1fr;
    }
    
    .icon-img {
      grid-column: 1/2;
      grid-row: 2/3;
    }
    
    .owner {
      grid-row: 3/4;
      grid-column: 1/2;
      flex-direction: row;
    }
    
    .owner-number {
      font-size: 16px;
    }
    
    .description {
      grid-column: 1/2;
      grid-row: 4/5;
      margin-top: 0;
    }
    
    .sell {
      order: 10;
    }
    
    .sell-button {
      grid-column: 1/2;
      grid-row: 6/7;
      order: 10;
    }
    
    .more-nft {
      font-size: 20px!important;
    }
  }
  
  @media screen and (max-width: 640px) {
    .more-nft-list {
      grid-template-columns: 1fr;
    }
    
    .sale-history-item {
      grid-column-gap: 5%;
    }
  }
  
  @media screen and (max-width: 500px) {
    .characteristics {
      grid-column-gap: 20px;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }

    .more-nft {
      font-size: 15px!important;
    }
    
    .owner {
      display: flex;
      flex-direction: column;
    }
    
    .history {
      .content {
        font-size: 10px;
      }
    }
  }
`;