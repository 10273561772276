import React from 'react';
import {MintMarketStyled} from "./MintMarket.styled";
import soon from '../../images/coming-soon.png'
import ship4 from '../../images/ship4.png'
import ship3 from '../../images/ship3.png'
import AnimatedImg from "../AnimatedImg/AnimatedImg";
import ProductsItem from '../ProductsItem/ProductsItem';
const ships = [
    {
    name: "Ship1",
        description: "Ship1",
        image: ship4,
        firstSale: true,
        amount: 4,
        metadata_uri: 'string',
    retries: 1,
        rarity: 0,
        season: 'Season 2',
        type: "SADAS",
        replicas: 4,
        allAmount: 50,
        owner: "0x02131",
        price: 100000000000000,
        isMyNft: false
    },
    {
        name: "Ship1",
            description: "Ship1",
            image: ship4,
            firstSale: true,
            amount: 4,
            metadata_uri: 'string',
            retries: 1,
            rarity: 1,
            season: 'Season 1',
            type: "SADAS",
            replicas: 4,
            allAmount: 10,
            owner: "0x02131",
            price: 2000000000000000,
            isMyNft: false
        },
        {
            name: "Ship1",
                description: "Ship1",
                image: ship3,
                firstSale: true,
                amount: 2,
                metadata_uri: 'string',
                retries: 1,
                rarity: 3,
                season: 'Season 1',
                type: "SADAS",
                replicas: 1,
                allAmount: 5,
                owner: "0x02131",
                price: 500000000000000000,
                isMyNft: false
            },
            {
                name: "Ship1",
                    description: "Ship1",
                    image: ship3,
                    firstSale: true,
                    amount: 1,
                    metadata_uri: 'string',
                    retries: 1,
                    rarity: 4,
                    season: 'Season 3',
                    type: "SADAS",
                    replicas:1,
                    allAmount: 2,
                    owner: "0x02131",
                    price: 2000000000000000000,
                    isMyNft: false
                }
]
const MintMarket: React.FC = () => {
    return (
        <MintMarketStyled>
            <div className="title">
                    <h2>The <span className="blue">&nbsp;marketplace </span>
                    </h2>
                    <img src={soon}/>
            </div>
            <div className="text">
                <p>
                    LIMINAL SPACE will build and implement own marketplace, where players can buy and sell NFT-objects, such as ships, engines and weapons.
                </p>
            </div>
            <div className="row">
                {ships.map((el,i)=>{
                    return (
                        <div  className="row_item" key={i}>
                            <ProductsItem product={el}></ProductsItem>
                        </div>
                    )
                })}

            </div>
        </MintMarketStyled>
    );
};

export default MintMarket;
