import React, {useEffect, useState} from 'react';
import {NavigationStyled} from "./Navigation.styled";
import {Link} from "react-router-dom";

import logoImg from "../../images/logo.png"
import homeImg from "../../images/svg/home.svg"
import marketplaceImg from "../../images/svg/marketplace.svg";
import contactsImg from "../../images/svg/contact.svg";
import {useInjection} from "inversify-react";
import AppStore from "../../stores/AppStore";
import {observer} from "mobx-react";


const Navigation: React.FC = observer(() => {
    const [isOpened, setIsOpened] = useState(false);
    const appStore = useInjection(AppStore);

    const Pages = [
        {
            page: 'home',
            path: '/',
            text: 'Home',
            img: homeImg,
        },
        {
            page: 'marketplace',
            path: '/marketplace',
            text: 'Marketplace',
            img: marketplaceImg,
        },
    ];

    const toggleMenu = () => {
        setIsOpened(!isOpened);
    }

    useEffect(() => {
        let currentHref = window.location.pathname;

        if (currentHref === '/mynft') {
            currentHref = '/marketplace'
        }

        appStore.changeActiveRoute(currentHref);
    })

    const activeRoute = appStore.activeRoute;

    return (
        <NavigationStyled className={isOpened ? 'opened' : ''}>
            <div className="logo">
                <img className="logo-img" src={logoImg} alt="logo"/>
                <div className="background-fade"/>
            </div>
            <nav className="main-nav">
                {
                    Pages.map((page, index) => {
                        return (
                            <Link
                                className={`nav-item ${page.path === activeRoute ? 'nav-item-active' : ''}`}
                                to={page.path}
                                key={index}>
                                <img className="nav-home-img" src={page.img} alt={page.text}/>
                                <span className="nav-label">{page.text}</span>
                            </Link>
                        )
                    })
                }
                <a className="nav-item" href='https://bithotel.io/#/contact'>
                    <img className="nav-home-img" src={contactsImg} alt={'Contacts'}/>
                    <span className="nav-label">Contacts</span>
                </a>
                <div className="button-holder mobile-hide">
                    <div className="whitepaper">
                        <a href="https://bithotel.io/#/whitepaper"
                           className="button"
                           target="_blank">
                            Whitepaper
                        </a>
                        <div className="fade"/>
                    </div>
                </div>
            </nav>
            <div className="hamburger">
                <label>
                    <input
                        onClick={() => toggleMenu()}
                        type="checkbox" id="check"
                    />
                    <span/>
                    <span/>
                    <span/>
                </label>
            </div>
        </NavigationStyled>
    );
});

export default Navigation;
