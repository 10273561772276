import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {GameStyled} from "./Game.styled";
import ConnectWalletBtn from "../../components/ConnectWalletBtn/ConnectWalletBtn";

import {observer} from "mobx-react";

import EmptyMarketplace from '../../components/EmptyMarketplace/EmptyMarketplace';
import { appearance } from '../Mint/MintPage';

interface Props {
    isMyNft: boolean,
}


const GamePage: React.FC<Props> = observer(({isMyNft}: Props) => {

    const [opacity, setOpacity] = useState<boolean>(false)

    return (
        <GameStyled>
            <div style={appearance(opacity)}>
                <ConnectWalletBtn  page="Game"/>
                {/* @ts-ignore */}
                <EmptyMarketplace setOpacity={setOpacity} title={'Game '}></EmptyMarketplace>
            </div>
        </GameStyled>
    );
});

export default GamePage;
