import {BigNumber} from 'ethers';
import BN from 'bignumber.js';
import numeral from "numeral";
import filter from "../components/Filter/Filter";
import {useInjection} from "inversify-react";
import FilterStore from "../stores/FilterStore";

export function toBNJS(val: BigNumber | number | string) {
    return new BN(val.toString());
}

export function maskAddress(address: string) {
    return address.slice(0, 6) + '...' + address.slice(address.length - 4)
}

export function fd(val: number | string | BN) {
    if (!val)
        return '';
    return numeral(val?.toString()).format('0,0[.][000000000000000000]')
}

export const pluralize = (count, noun, suffix = 's') =>
    `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const sortByDate = (firstDate, secondDate) => {
    return secondDate - firstDate;
}

export const Sort = {
    'popular': 'popular:desc',
    'lowestprice': 'price:asc',
    'highestprice': 'price:desc',
    'latest': 'latest_id:desc',
    'epic': 'rarity:desc',
}

export const getFullUrl = (array, filterName: string, url: string, isInitial: boolean) => {
    if (array.length === 1 && isInitial) {
        if (filterName === 'sortBy') {
            url += `/?${filterName}=${Sort[array[0]]}`
        } else {
            url += `/?${filterName}=${array[0]}`
        }
    }

    if (array.length === 1 && !isInitial) {
        if (filterName === 'sortBy') {
            url += `&&${filterName}=${Sort[array[0]]}`
        } else {
            url += `&&${filterName}=${array[0]}`
        }
    }

    if (array.length > 1 && isInitial) {
        array.forEach((item, index) => {
            if (index === 0) {
                url += `/?${filterName}=${array[index]}`;
            } else {
                url += `&&${filterName}=${array[index]}`;
            }
        })
    }

    if (array.length > 1 && !isInitial) {
        array.forEach((item, index) => {
            url += `&&${filterName}=${array[index]}`;
        })
    }

    return url;
}

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export const generateEvenNumbers = (max) => {
    const numbers = [];

    for (let i = 2; i <= max; i = i + 2) {
        numbers.push(i);
    }

    return numbers;
}

export const getPaginationSlice = (array, page, maxPerPage) => {
    const min = maxPerPage * (page - 1);
    const max = maxPerPage * page;

    return array.slice(min, max);
}

const rarityArray = ['common', 'uncommon', 'rare', 'epic', 'legendary'] as const
export type Rarities = typeof rarityArray[number]

export const getRarityFromNumber = (rarity: number) =>(
   rarity >= 0 && rarity <= 4 ? rarityArray[rarity] : rarityArray[0]
) 

BN.config({EXPONENTIAL_AT: 100})