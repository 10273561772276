import React from 'react';
import { useMediaQuery } from '../../../utils/hooks';
import {SeasonLabelStyled} from "./SeasonLabel.styled";

interface Props {
    season: string,
}

const SeasonLabel: React.FC<Props> = ({season}: Props) => {
    const isFull = useMediaQuery('(min-width: 1300px)')
    const i = season && season.split(' ');
    const seasonNumber = i && i[i.length - 1];
    const seasonToRender = isFull ? season : `${season[0]}${seasonNumber}`

    return (
        <SeasonLabelStyled className={`season season-${seasonNumber}`}>
            {seasonToRender}
        </SeasonLabelStyled>
    );
};

export default SeasonLabel;

