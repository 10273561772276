import React from 'react';
import {FooterStyled} from "./Footer.styled";
import emailIcon from "../../images/email-icon.png"
import twitterIcon from "../../images/twitter-icon.png"
import websiteIcon from "../../images/website-icon.png"
import discordIcon from "../../images/discord-icon.png"
import teamLogo from "../../images/team-logo.png";
import {Icon} from "../UIcomponents/Icons/icons";

const Footer: React.FC = () => {
    return (
        <FooterStyled id="footerBlock">
            <div className="footer__about">
                <div className="logo__wrapper">
                    <img src={teamLogo}/>
                    <span>Another.dev</span>
                </div>
                <a href="https://adev.co/" target="_blank">Website<Icon classname="arrow-right" icon="arrowRight" /></a>
            </div>
            <h2>See you
                <br/>
                <span className="pink">Liminal space cowboy...</span>
            </h2>
            <ul className="social__list">
                <li className="social__item">
                    <a href="mailto:info@adev.co">
                        {/*<img src={emailIcon}/>*/}
                        <Icon classname="social__icon" icon="emailIcon" />
                    </a>
                </li>
                <li className="social__item">
                    <a href="https://twitter.com/0xLiminalSpace" target="_blank">
                        {/*<img src={twitterIcon}/>*/}
                        <Icon classname="social__icon" icon="twitterIcon" />
                    </a>
                </li>
                <li className="social__item">
                    <a href="https://adev.co/" target="_blank">
                        {/*<img src={websiteIcon}/>*/}
                        <Icon classname="social__icon" icon="websiteIcon" />
                    </a>
                </li>
                <li className="social__item">
                    <a href="https://discord.com/channels/986653891422740530/992508169634988092" target="_blank">
                        {/*<img src={discordIcon}/>*/}
                        <Icon classname="social__icon" icon="discordIcon" />
                    </a>
                </li>
            </ul>
            <span className="copyright">&#169; another.dev, 2022</span>
        </FooterStyled>
    );
};

export default Footer;
