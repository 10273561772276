import {makeObservable, observable, runInAction} from "mobx";
import {RootStore} from "./RootStore";

export class AppStore {
    public activePage: string;
    public isSellNftModalShown: boolean = false;
    public isSuccessModalShown: boolean = false;
    public isSuccessSellModalShown: boolean = false;
    public tokenToSale: number = null;
    public activeRoute: string = '';
    public previousRoute: string = '';
    public marketplacePage: number = 1;

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this, {
            activePage: observable,
            isSellNftModalShown: observable,
            isSuccessModalShown: observable,
            tokenToSale: observable,
            activeRoute: observable,
            isSuccessSellModalShown: observable,
            previousRoute: observable,
            marketplacePage: observable,
        })
    }

    changeActivePage(selectedPage: string) {
        runInAction(() => {
            this.activePage = selectedPage;
        })
    }

    changeActiveRoute(selectedRoute: string) {
        runInAction(() => {
            this.activeRoute = selectedRoute;
        })
    }

    changeSellNftModalView(status: boolean) {
        runInAction(() => {
            this.isSellNftModalShown = status;
        })
    }

    changeSuccessModalView(status: boolean) {
        runInAction(() => {
            this.isSuccessModalShown = status;
        })
    }

    changeSuccessSellModalView(status: boolean) {
        runInAction(() => {
            this.isSuccessSellModalShown = status;
        })
    }

    selectTokenToSale(id: number) {
        runInAction(() => {
            this.tokenToSale = id;
        })
    }

    writePreviousRoute(route) {
        runInAction(() => {
            this.previousRoute = route;
        })
    }

    setMarketplacePage(page) {
        runInAction(() => {
            this.marketplacePage = page;
        })
    }

}

export default AppStore;