import styled from "styled-components";
import metamaskImg from "../../images/svg/metamask-icon.svg";
import coinbaseImg from "../../images/coinbase.png";
import closeImg from "../../images/svg/close-icon.svg";

export const ConnectWalletStyled = styled.div`
  color: ${({theme}) => theme.colors.primaryText};
  text-align: left;

  & > div {
    z-index: 9999;
  }

  .modal-title {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 20px;

    &-green {
      color: ${({theme}) => theme.colors.accent};
      text-shadow: 0 4px 25px rgba(113, 218, 180, 0.5);
    }
  }

  .modal-description {
    margin-bottom: 20px;
  }

  .wallets {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: left;
  }

  .wallet {
    position: relative;
    font-size: 20px;
    font-weight: 800;
    text-align: left;
    padding: 20px 30px 20px 60px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primaryText};
    background: ${({theme}) => theme.colors.background};
    border: 1px solid ${({theme}) => theme.colors.border};
    box-shadow: 0 4px 45px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      left: -10px;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 34px;
      height: 29px;
    }

    &-metamask {
      &:before {
        background-image: url('${metamaskImg}');
        left: 15px;
        top: 15px;
      }
    }

    &-coinbase {
      &:before {
        background-image: url('${coinbaseImg}');
        left: 15px;
        top: 15px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 18px;
      width: 44px;
      height: 42px;
      background: #2E2E3C;
      box-shadow: 0 4px 45px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
    }
  }

  .follow-btn {
    margin-left: auto;
    position: relative;
    z-index: 100;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background-image: url('${closeImg}');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
  }
`;
