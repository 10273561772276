import styled from "styled-components";
import closeImg from "../../images/svg/close-icon.svg";

export const SuccessModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0008;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .modal-container {
    position: relative;
    padding: 40px;
    text-align: left;
    width: 483px;
    box-shadow: 0 4px 45px rgba(0, 0, 0, 0.25);
    min-width: 200px;
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    background: linear-gradient(90.6deg, #2E2E3C 55.82%, #23232F 98.55%);
  }

  .title {
    text-transform: uppercase;
    color: white;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 21px;
  }

  .title-label {
    color: #6DF3D3;
    text-shadow: 0 4px 25px rgba(113, 218, 180, 0.5);
  }

  .text {
    line-height: 24px;
    margin-bottom: 24px;
  }

  .confirm-btn {
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primaryText};
    background: linear-gradient(358.84deg, #3CC4D7 -38.41%, rgba(109, 243, 211, 0.5) 17.27%, rgba(97, 254, 160, 0) 63.69%);
    border: 1px solid ${({theme}) => theme.colors.border};
    padding: 15px 15px 16px;
    border-radius: 10px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: calc(100% - 12px);
      height: calc(100% - 5px);
      top: -1px;
      left: 6px;
      right: 6px;
      z-index: 3;
      background: linear-gradient(87.46deg, #3CC4D7 -3.04%, #6DF3D3 92.88%, #61FEA0 172.86%);
      border-radius: 10px;
    }
  }

  .confirm-btn-text {
    z-index: 4;
    position: relative;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    background-image: url('${closeImg}');
    background-size: 100%;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
  }

  &.hidden {
    display: none;
  }
`;