import styled from "styled-components";
import saleImg from "../../images/svg/sale.svg";

interface Props {
  isListView?: boolean
}

export const ProductsItemStyled = styled.article<Props>`
  display: flex;
  flex-direction: ${({isListView}) => isListView ? `row` : 'column'};
  /* padding: 15px 25px; */
  background: linear-gradient(180deg, #2E2E3C 55.66%, #23232F 125.89%);
  box-shadow: 0 4px 45px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  min-width: 0px;
  max-width: 300px;
  padding-bottom: 21px;
  padding-top: 15px;
  ${({isListView}) => isListView ? `max-height: 130px` : ''};
  ${({isListView}) => isListView ? `justify-content: space-between` : ''};

  .top-info {
    ${({isListView}) => isListView ? `order: 1` : ''};
    ${({isListView}) => isListView ? `align-items: center;` : ''};
    display: flex;
    padding-left: 23px;
    padding-right: 23px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: ${({isListView}) => isListView ? '0' : '16px'};
  }

  .product-info-wrapper {
    ${({isListView}) => isListView ? `
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      border-right: 1px solid #717185;
      flex-grow: 2;
      order: 0;
    ` : ``}
  }

  .bottom-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({isListView}) => isListView ? '0' : '16px'}; 
    ${({isListView}) => isListView ? `align-items: center` : ''};
    flex-grow: 1;
    width: 180px;
    margin-left: 52px;
  }

  &:hover {
    transition: all 0.2s;
    outline: 1px solid ${({theme}) => theme.colors.primaryText};

    .buy-button:not(:disabled) {
      background: linear-gradient(358.84deg, #3CC4D7 -38.41%, rgba(109, 243, 211, 0.5) 17.27%, rgba(97, 254, 160, 0) 63.69%);
      border-radius: 10px;
      position: relative;
      z-index: 2;

      .buy-button-text {
        position: relative;
        z-index: 4;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% - 12px);
        height: calc(100% - 5px);
        top: -1px;
        left: 6px;
        right: 6px;
        z-index: 3;
        background: linear-gradient(87.46deg, #3CC4D7 -3.04%, #6DF3D3 92.88%, #61FEA0 172.86%);
        border-radius: 10px;
      }
    }
  }

  .season {
    font-size: 16px;
    color: ${({theme}) => theme.colors.primaryText};
    border-radius: 5px;
    ${({isListView}) => isListView ? `margin-left: 2rem` : ''};
    ${({isListView}) => isListView ? `order: 1` : ''};
  }

  .product-img-wrapper {
    position: relative;
    margin-bottom: 20px;
    ${({isListView}) => isListView ? `order: 0; padding-left: 10%; padding-right: 10%;` : ''}
    

    //&:before {
    //  content: "";
    //  background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
    //  display: block;
    //  position: absolute;
    //  width: 125px;
    //  height: 125px;
    //  left: -34px;
    //  border-radius: 50%;
    //}
    //
    //&:after {
    //  content: "";
    //  width: 200px;
    //  height: 200px;
    //  border-radius: 50%;
    //  display: block;
    //  background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
    //  opacity: 0.5;
    //  filter: blur(200px);
    //  position: absolute;
    //  left: -66px;
    //  top: -30px;
    //  animation: lightup infinite;
    //  animation-duration: 4s;
    //}
  }

  .product-img {
    display: flex;
    justify-content: center;
    z-index: 10;
    position: relative;
    max-height: ${({isListView}) => isListView ? '100px' : '180px'};
    object-fit: scale-down;
    margin: auto;
  }

  .rarity {
    min-width: 25px;
    ${({isListView}) => isListView ? `order: 0` : ''};
  }

  .rarity-levels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rarity-title {
    margin-bottom: 10px;
    text-align: center;
  }

  .rarity-level {
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      bottom: -20px;
      left: 0;
      display: block;
      width: 10px;
      height: 10px;
      content: "";
      border-radius: 50%;
      background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
      border: 1px solid #1d1d23;
    }
  }

  .rarity-1 {
    .rarity-level:first-child:before {
      background: #63FCA3;
      border: 1px solid #3D9865;
    }
  }

  .rarity-2 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before {
      background: #63FCA3;
      border: 1px solid #3D9865;
    }
  }

  .rarity-3 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before,
    .rarity-level:nth-child(3):before {
      background: linear-gradient(132.71deg, #F1CB69 -12.6%, #F17969 112.6%);
      border: 1px solid #9b4f44;
    }
  }

  .rarity-4 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before,
    .rarity-level:nth-child(3):before,
    .rarity-level:nth-child(4):before {
      background: linear-gradient(132.71deg, #F1CB69 -12.6%, #F17969 112.6%);
      border: 1px solid #9b4f44;
    }
  }

  .rarity-5 {
    .rarity-level:before {
      background: linear-gradient(180deg, #C63B3B 0%, #EE7F7F 100%);
      border: none;
    }
  }

  .season-1 {
    background: linear-gradient(99.11deg, #4BDE88 -31.56%, #82FC63 215.5%);
  }

  .season-2 {
    background: linear-gradient(95.91deg, #EDD376 31.76%, #FFFA88 219.49%);
  }

  .season-3 {
    background: linear-gradient(109.12deg, #FD2929 -14.05%, #FE66C1 134.06%);
  }

  .season-4 {
    background: linear-gradient(100.01deg, #07E9C0 11.47%, #07B2E9 143.84%);
  }

  .product-name {
    margin-bottom: 13px;
    max-width: 100%;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 800;
  }

  .section-title {
    color: #525f6e;
  }

  .quantity {
    // margin: 0 30px;
  }

  .product-amount {
    color: white
  }

  .price {
    
  }
  .quantity-number, .price-number {
    margin-bottom: 5px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .quantity-title, .price-title {
    margin-bottom: 5px;
    
  }
  .buy-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .buy-button{
    ${({isListView}) => isListView ? `flex-grow: 1` : ''};
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    width: 180px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.primaryText};
    background-color: ${({theme}) => theme.colors.background};
    border: 1px solid ${({theme}) => theme.colors.border};
    padding: 15px 15px 16px;
    border-radius: 14px;
    position: relative;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({isListView}) => isListView ? `align-self: center` : ''};

    &:hover:not(:disabled) {
      background: linear-gradient(87.46deg, #3CC4D7 -3.04%, #6DF3D3 92.88%, #61FEA0 172.86%);

      &:before {
        content: '';
        position: absolute;
        display: block;
        width: calc(100% - 12px);
        height: calc(100% - 5px);
        top: -1px;
        left: 6px;
        right: 6px;
        z-index: 3;
        background: linear-gradient(358.9deg, #3CC4D7 -38.49%, rgba(109, 243, 211, 0.5) 65.68%, rgba(97, 254, 160, 0) 152.53%);
        filter: drop-shadow(0px 4px 25px rgba(113, 218, 180, 0.5));
        border-radius: 10px;
      }
    }
  }

  .buy-button-text {
    display: block;
    margin-top: -1px;
  }

  .number-field {
    background: linear-gradient(90deg, #2E2E3C -80%, #23232F 85.82%);
    border-radius: 5px;
    padding: 7px;
    text-align: center;
  }

  .for-sale-label {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.accent};
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 0;

    &:after {
      content: '';
      height: 14px;
      width: 14px;
      margin-left: 7px;
      display: inline;
      background-image: url('${saleImg}');
      background-size: 100%;
    }
  }

  .data {
    grid-column: 1/-1;
  }

  &.mynft {
    grid-template-rows: 90px 3fr 1fr;
  }
  
  .buy-button:disabled {
    opacity: 0.3;
  }
`;
