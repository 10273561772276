import styled from "styled-components";
import mobileMenuBg from "../../images/mobile-menu-bg.png"

interface Button {
  active?: boolean
}

export const LinkButton = styled.button<Button>`
  position: relative;
  display: flex;
  justify-content: center;
  letter-spacing: 0.035em;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  text-align: center;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  //height: 50px;
  height: 45px;
  background: ${(props) => !props.active?
      "linear-gradient(87.46deg, rgba(184, 184, 184, 0.24) -3.04%, rgba(32, 57, 146, 0.2) 133.03%, rgba(159, 159, 159, 0.28) 172.86%);":
      "linear-gradient(87.46deg, #3F3CD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);"};

  opacity: ${(props) => !props.active? 0.5:1};
  border-radius: 10px;
  border: 0;
  font-weight: 800;
  margin-right: 16px;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Dosis", sans-serif;
  color: white;
  cursor: pointer;
  
  &:hover,
  &:focus {
    opacity: 1;
  }
  
  &:active {
    opacity: 0.7;
  }
  
  .nav-icon {
    margin-left: 10px;
    stroke: white;
  }
  
  .nav-icon--last {
    margin-right: 0;
  }
`

export const ConnectWalletBtnStyled = styled.div`
  margin-bottom: 100px;
  padding: 15px 60px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  a {
    position: relative;
  }
  
  .more:active button,
  .more:hover button,
  .more:focus button {
    opacity: 1;
  }

  .dropdown-wrapper {
    display: none;
  }
  
  .more {
    position: relative;
  }
  
  .more:hover .dropdown-wrapper,
  .more:focus .dropdown-wrapper,
  .more:active .dropdown-wrapper {
    display: block;
    position: absolute;
    top: 45px;
    left: 0;
    width: 104px;
    height: fit-content;
    padding: 15px 20px;
    background: linear-gradient(87.46deg,rgba(184,184,184,0.24) -3.04%,rgba(32,57,146,0.2) 133.03%,rgba(159,159,159,0.28) 172.86%);
    border-radius: 10px;
  }
  
  .dropdown__list {
    padding: 0;
  }
  
  .dropdown__item {
    margin-bottom: 2px;
    opacity: 0.5;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .dropdown__item a {
    font-weight: 800;
    margin-right: 16px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Dosis",sans-serif;
    color: white;
    cursor: pointer;
    text-decoration: none;
  }
  
  .dropdown__item:hover,
  .dropdown__item:focus {
    opacity: 1;
  }

  .dropdown__item:active {
    opacity: 0.7;
  }
  
  .header {
    display: flex;
    padding-top: 12px;
    margin-left: 30px;
  }
  
  .logo__wrapper {
    text-decoration: none;

    &:hover,
    &:focus {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.4;
    }
  }
  
  .logo {
    display: flex;
    align-items: center;
  }
  
  .logo-icon {
    width: 41px;
    height: 87px;
    margin-right: 26px;
  }
  
  .logo-text {
    max-width: 121px;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 107.4%;
    letter-spacing: 0.23em;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    background: linear-gradient(90deg, #3F3CD7 9.56%, #6D73F3 109.69%, #6171FE 193.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .soon {
    position: absolute;
    content: '';
    right: 11px;
    top: -9px;
    width: 43px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(264.06deg, #C63B3B -68.99%, #D174E0 40.01%, #70BAFF 130.88%), linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
    border-radius: 28px;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: white;
  }
  
  //.nav-icon path {
  //  fill: white;
  //  stroke: white;
  //}
  
  .mobile__menu {
        display: none;
    }
  .mobile__menu__open {
    position: fixed;
    top: 0;
    color: white !important;
    align-items: center;
    padding-top: 50px;
    left: 0;
    font-size: 40px;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index:10;
    background-color: rgba(0, 0, 0, 0.7);
}
  
  .mobile__menu__open a {
    color: white !important;
  }
  
  .connect-wallet {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    //margin-left: auto;
    text-align: center;
    width: 204px;
    height: 50px;
    background: linear-gradient(87.46deg, #3F3CD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);
    border-radius: 10px;
    border: 0;
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Dosis';
    letter-spacing: 0.035em;
    color: white;
    cursor: pointer;
    margin-top: 11px;
    z-index: 1;
    
    &:focus,
    &:hover {
      opacity: 0.6;
    }
    
    &:active {
      opacity: 0.4;
    }
  }
  .connect-wallet img {
    margin-left: 13px
  }
  
  @media (max-width: 1439px) {
    margin-bottom: 20px;
    padding: 19px 24px;
    
    .header {
      padding-top: 0;
      
      & a:nth-child(2) {
        width: 164px;
      }
      
      & a:last-child {
        width: 204px;
      }
    }
    
    .connect-wallet {
      margin-top: 0;
    }
    
    .logo-icon {
      width: 29px;
      height: 55px;
      margin-right: 18px;
    }
    
    .logo-text {
      font-size: 18px;
      line-height: 107%;
      max-width: 86px;
    }
    
    .more {
      display: none;
    }
  }
  
  .mobile__menu__open {
    display: none;
  }
  
  @media (min-width: 1024px) {
    mobile__menu__open {
      display: none!important;
    }
  }
  
  @media only screen and (max-width: 1023px) {
    padding: 12px 14px;
    margin-bottom: 0;
    
    .logo__wrapper {
      z-index: 11;
    }
    
    .mobile__menu {
      z-index: 11;
      background: linear-gradient(87.46deg, #3C4BD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);
      padding: 6px 9px 2px 9px;
      border-radius: 12px;
    }
    
    .mobile-menu-icon {
      stroke: white;
    }
    
    .mobileMenuItem {
      text-decoration: none;
      font-weight: 800;
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      margin-bottom: 37px;
      display: flex;
      align-items: center;

      &:hover,
      &:focus {
        opacity: 0.6;
      }

      &:active {
        opacity: 0.4;
      }
    }

    //.mobileMenuItem--disabled {
    //  pointer-events: none;
    //}
    
    .more {
      cursor: pointer;
      
      &:hover,
      &:focus {
        opacity: 0.6;
      }

      &:active {
        opacity: 0.4;
      }
    }
    
    .connect-wallet {
      //right: 110px;
      //top: 35px;
      display: none;
    }
    .mobile__menu {
        display: block;
        cursor: pointer;
      
      &:hover,
      &:focus {
        opacity: 0.6;
      }
      
      &:active {
        opacity: 0.4;
      }
    }

    .mobile__menu__open {
      align-items: flex-start;
      padding: 97px 16px;
      background: #292C41;
      
      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(${mobileMenuBg});
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    
    .mobile__menu__open .nav-icon {
      margin-left: 17px;
      stroke: white;
    }
    
    .nav-icon--home {
      width: 25px;
      height: 25px;
      margin-bottom: 2px;
    }
    
    .nav-icon--play {
      width: 24px;
      height: 25px;
      margin-bottom: 2px;
    }
    
    .nav-icon--marketplace {
      width: 25px;
      height: 25px;
      margin-bottom: 2px;
    }
    
    .nav-icon--mint {
      width: 30px;
      height: 25px;
      margin-bottom: 1px;
    }
    
    .nav-icon--more {
      width: 24px;
      height: 18px;
    }
    
    .mobile__menu__open .connect-wallet {
      display: flex;
      width: unset;
      height: unset;
      margin-left: 0;
      font-weight: 800;
      font-size: 24px;
      line-height: 37px;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      margin-bottom: 37px;
      background: none;
    }

    .mobile__menu__open .more {
      display: block;
    }
    
    .header {
      
      display: none;
    }
    
    .logo-icon {
      width: 26px;
      height: 41px;
      margin-right: 6px;
    }
    
    .logo-text {
      font-size: 16px;
      line-height: 17px;
    }
    
  }

  @media only screen and (max-width: 800px) {
    padding-right: 15px;
    //.connect-wallet {
    //  height: 40px;
    //  line-height: 14px;
    //  width: 140px;
    //  font-size: 10px;
    //  top: 40px;
    //  margin-left: 15px;
    //}
  }
`;
