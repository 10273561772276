import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {MintStyled} from "./Mint.styled";

import ConnectWalletBtn from "../../components/ConnectWalletBtn/ConnectWalletBtn";

import {observer} from "mobx-react";

import EmptyMarketplace from '../../components/EmptyMarketplace/EmptyMarketplace';
import MintLive from '../../components/MintLive/MintLive';
import MintMarket from '../../components/MintMarket/MintMarket';
import EvolvingNFT from '../../components/EvolvingNFT/EvolvingNFT';
import Roadmap from '../../components/Roadmap/Roadmap';

interface Props {
    isMyNft: boolean,
}

export const appearance = (opacity:boolean) =>{
    return {
        opacity: opacity?1:0,
        transition: '500ms ease all'
    }
}
const MintPage: React.FC<Props> = observer(({isMyNft}: Props) => {
    const [opacity, setOpacity] = useState<boolean>(false)
    useEffect(() => {
        setOpacity(true)
    }, [])

    return (
        // <div></div>
        <MintStyled>
            <div style={appearance(opacity)}>
                <ConnectWalletBtn  page="Mint"/>
                {/* @ts-ignore */}
                <MintLive setOpacity={setOpacity}></MintLive>
                <MintMarket></MintMarket>
                <EvolvingNFT></EvolvingNFT>
            </div>
            {/*<div>*/}
            {/*    <ConnectWalletBtn  page="Mint"/>*/}
            {/*    <MintLive></MintLive>*/}
            {/*    <MintMarket></MintMarket>*/}
            {/*    <EvolvingNFT></EvolvingNFT>*/}
            {/*</div>*/}
        </MintStyled>
    );
});

export default MintPage;
