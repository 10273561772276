import styled from "styled-components";

export const NavigationStyled = styled.div`
  position: fixed;
  left: 20px;
  z-index: 10;

  .logo {
    width: 250px;
    height: 140px;
    position: relative;
    z-index: 5;
    text-align: center;
  }

  .logo-img {
    width: 80%;
  }

  .background-fade {
    position: absolute;
    width: 48px;
    height: 48px;
    left: 105px;
    top: 30px;
    background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
    filter: blur(80px);
  }

  .hamburger {
    display: none;
  }

  .main-nav {
    width: 250px;
    height: 600px;
    background: green;
    position: relative;
    background: linear-gradient(180deg, #2E2E3C 55.66%, #23232F 90.9%);
    border-radius: 5px;
    padding-top: 15px;
  }

  .nav-item {
    width: 90%;
    height: 50px;
    margin-left: 5%;
    background: #23232F;
    border-radius: 10px;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;
    line-height: 50px;
    color: #fff;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    text-transform: uppercase;
    text-align: left;
    position: relative;

    .overlay-nav {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: 5555;
      border-radius: 10px;
    }

    .comming {
      width: 100px;
      height: 30px;
      background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
      position: absolute;
      right: -40px;
      top: 7px;
      border-radius: 10px;
      z-index: 55555;
      text-align: center;
      font-size: 9px;
      line-height: 30px;
      color: #fff;
      text-transform: initial;
    }

    .nav-label {
      margin-left: 45px;
    }

    img {
      position: absolute;
      top: 10px;
      left: 5px;
    }

    .nav-home-img {
      top: 13px;
      left: 8px;
    }

    .nav-game-img {
      top: 15px;
      left: 7px;
    }

    .nav-marketplace-img {
      left: 7px;
    }

    .nav-contact-img {
      top: 15px;
    }
  }

  .nav-item-active {
    background: ${({theme}) => theme.gradients.cold};
  }

  .button-holder {
    height: 60px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 20px;

    .whitepaper {
      height: 60px;
      width: 90%;
      margin-left: 5%;
      position: relative;

      a {
        display: block;
        height: 50px;
        width: 98%;
        margin-left: 1%;
        margin-top: 7px;
        margin-bottom: -10px;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        background: linear-gradient(264.06deg, #C63B3B -68.99%, #D174E0 40.01%,
        #70BAFF 130.88%),
        linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
        border-radius: 10px;
        border: 0;
        color: #fff;
        position: relative;
        z-index: 55555;
        line-height: 50px;
        text-decoration: none;

        &:hover {
          cursor: pointer;
        }
      }

      .fade {
        height: 60px;
        width: 100%;
        position: absolute;
        top: -5px;
        left: 0;
        background: linear-gradient(7.72deg, #C63B3B -44.04%, rgba(209, 116, 224, 0.5) 12.94%,
        rgba(112, 186, 255, 0) 60.45%);
        border-radius: 10px;
      }
    }
  }

  label {
    display: flex;
    flex-direction: column;
    width: 50px;
    cursor: pointer;
  }

  label span {
    background: #fff;
    border-radius: 10px;
    height: 5px;
    margin: 5px 0;
    transition: .4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(6px, 0px)
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg)
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(22px, -8px) rotatez(45deg);
  }

  // Ipad
  @media only screen and (max-width: 1023px) {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    padding-right: 30px;

    .logo {
      display: block;
      position: relative;
      height: 104px;
      width: 204px;
    }

    .comming {
      top: 10px !important;
      right: 20px !important;
    }

    .main-nav {
      display: none;
      width: 100%;
      left: 0;
      margin-top: -20px;
      height: calc(100vh - 125px);
      background: #23232F;
      z-index: 55;
    }

    input[type="checkbox"]:checked html {
      display: none !important;
    }

    .hamburger {
      position: relative;
      margin-top: -20px;
      z-index: 555;
      display: block;
      height: 40px;
    }

    .activeM {
      display: block;
      margin-top: -140px;
      height: 100vh;
      padding-top: 140px;
    }

    .whitepaper {
      bottom: 40px;
      margin-left: 0 !important;
      width: 230px !important;
    }

    .main-nav .button-holder .whitepaper .fade {
      top: 0 !important;
    }

    .mobile-hide {
      display: none;
    }

    .mobile-show {
      display: block;
    }

    &.opened {
      display: grid;
      grid-row-gap: 50px;
      padding: 0;
      position: relative;
      justify-content: normal;


      .logo {
        display: none;
      }

      .hamburger {
        order: -1;
        margin-top: 0;
        margin-left: auto;
      }

      .main-nav {
        display: block;
        margin: 0;
        padding: 0;
        height: 100%;
      }

      .nav-item {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: auto;
      }
    }
  }

  @media (max-width: 767px) {
    top: unset;
    left: unset;
    right: unset;
    bottom: 0;
    position: fixed;
    z-index: 20;
    max-height: 84px;
    background-color: #23232F;
    border-top: 1px solid #454558;

    .logo {
      width: 108px;
      height: 100%;
    }

    .logo-img {
      margin-left: 20px;
      margin-top: 11px;
      margin-bottom: 0;
      width: 108px;
      height: 100%;
    }

    .hamburger {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 0;
      width: 24px;
      height: 24px;

      label {
        width: 24px;

        span {
          width: 100%;
          height: 4px;
          margin-bottom: 2px;
          margin-top: 2px;
        }
      }
    }

    &.opened {
      display: grid;
      grid-row-gap: 50px;
      padding: 0;
      position: fixed;
      justify-content: normal;

      .logo {
        display: none;
      }

      .hamburger {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 33px;
        width: 24px;
        height: 24px;
      }

      .main-nav {
        top: unset !important;
        margin-bottom: 450px;
        display: block;
        height: 100%;
      }

      .nav-item {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        height: auto;
        z-index: 19;
      }
    }

    input[type="checkbox"]:checked ~ span:nth-of-type(1) {
      transform-origin: bottom;
      transform: rotatez(45deg) translate(5px,3px);
    }

    input[type="checkbox"]:checked ~ span:nth-of-type(2) {
      transform-origin: top;
      transform: rotatez(-45deg)
    }

    input[type="checkbox"]:checked ~ span:nth-of-type(3) {
      display: none;
    }
  } 
`;
