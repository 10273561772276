import {RootStore} from "./stores";
import {
    Router,
    Route,
} from "react-router-dom";
import {Theme} from "./styles/Theme";
import {Provider} from "inversify-react";
import {ToastContainer} from "react-toastify";
import {createBrowserHistory} from "history";
import React, {useEffect} from "react";
import {ModalsContainer} from "./modals";
import HeroPage from "./pages/HeroPage/HeroPage";
import MarketplacePage from "./pages/MarketplacePage/MarketplacePage";
import SelectedProduct from "./components/SelectedProduct/SelectedProduct";
import SellNftModal from "./modals/SellNftModal/SellNftModal";
import SuccessModal from "./modals/SuccessModal/SuccessModal";
import SuccessSellModal from "./modals/SuccessSellModal/SuccessSellModal";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import {Helmet} from "react-helmet";
import MintPage from "./pages/Mint/MintPage";
import GamePage from "./pages/Game/GamePage";
// import faviconImg from "../src/images/favicon.ico";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
    const rootStore = new RootStore();
    const history = createBrowserHistory();
    const container = rootStore.container;


    useEffect(() => {
        rootStore.walletStore.tryReconnect();

        !window.localStorage.jwt
            ? rootStore.web3Store.initInfuraWeb3()
            : rootStore.web3Store.initWeb3();
    }, [])

    return (
        <Theme>
            {/* <Helmet>
                <link rel="shortcut icon" type="image/jpg" href={faviconImg}/>
            </Helmet> */}
            <Router history={history as any}>
                <Provider container={container}>
                    <Route exact path={`/marketplace/:id`} component={SelectedProduct}/>
                    <PrivateRoute
                        exact
                        path='/mynft'
                        render={() => {
                            return (
                                <MarketplacePage isMyNft={true}/>
                            )
                        }}
                    />
                    <Route exact path='/mynft/:id' component={SelectedProduct}/>
                    <Route exact path='/' component={HeroPage}/>
                    <Route exact path='/marketplace' component={MarketplacePage}/>
                    <Route exact path='/game' component={GamePage}/>
                    <Route exact path='/mint' component={MintPage}/>
                    <ToastContainer/>
                    <ModalsContainer/>
                    <SellNftModal/>
                    <SuccessModal/>
                    <SuccessSellModal/>
                </Provider>
            </Router>
        </Theme>
    )
}


export default App
