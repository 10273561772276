import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {MarketplaceStyled} from "./Marketplace.styled";
import Navigation from "../../components/Navigation/Navigation";
import ConnectWalletBtn from "../../components/ConnectWalletBtn/ConnectWalletBtn";
import Filter from "../../components/Filter/Filter";
import ProductsList from "../../components/ProductsList/ProductsList";
import {DataStore} from "../../stores/DataStore";
import LoadWrapper from "../../components/LoadWrapper/LoadWrapper";
import {observer} from "mobx-react";
import {Web3Store} from "../../stores/Web3Store";
import {useInjection} from "inversify-react";
import Pagination from "../../components/Pagination/Pagination";
import FilterStore from "../../stores/FilterStore";
import {useMediaQuery} from '../../utils/hooks';
import AppStore from "../../stores/AppStore";
import {marketplaceTokenInterface} from "../../Interfaces";
import EmptyMarketplace from '../../components/EmptyMarketplace/EmptyMarketplace';
import { appearance } from '../Mint/MintPage';

interface Props {
    isMyNft: boolean,
}


const MarketplacePage: React.FC<Props> = observer(({isMyNft}: Props) => {
    const web3Store = useInjection(Web3Store);
    const dataStore = useInjection(DataStore);
    const filterStore = useInjection(FilterStore);
    const appStore = useInjection(AppStore);


    useEffect(() => {
        const account = web3Store?.account;

        isMyNft
            ? dataStore.getMyTokens(account)
            : dataStore.getTokens(appStore?.marketplacePage);
    }, [web3Store?.account, isMyNft, filterStore, appStore?.marketplacePage]);

    let tokens: marketplaceTokenInterface[] = [];
    let isLoaded: boolean = false;
    const account = web3Store?.account;

    isMyNft
        ? tokens = dataStore.myNfts
        : tokens = dataStore.marketplaceTokens;

    isMyNft
        ? isLoaded = dataStore.isMyNftsLoaded
        : isLoaded = dataStore.isMarketplaceTokensLoaded;

    type View = 'grid' | 'list'
    const [opacity, setOpacity] = useState<boolean>(false)
    const [viewType, setViewType]: [View, Dispatch<SetStateAction<View>>] = useState('grid')
    const toggleView = () => {
        setViewType(viewType === 'grid' ? 'list' : 'grid')
    }
    const isMobile = !useMediaQuery('(min-width: 768px)');
    useEffect(() => {
        if (isMobile && viewType !== 'grid') {
            toggleView()
        }
    }, [isMobile, tokens])
    

    return (
        <MarketplaceStyled>
            {/* <Navigation/> */}
            {/* <div className="main-content"> */}
                <div style={appearance(opacity)}>
                    <ConnectWalletBtn  page="Market"/>
                    {/* @ts-ignore */}
                    <EmptyMarketplace setOpacity={setOpacity}  title={'Marketplace '}></EmptyMarketplace>
                </div>
                {/* <Filter account={account} isMyNft={isMyNft} toggleView={toggleView}/>
                <LoadWrapper isDataLoaded={isLoaded}>
                    {
                        !tokens
                            ? <p>Tokens list is empty</p>
                            : <ProductsList className={'products-list'} products={tokens}
                                            isListView={!(viewType === 'grid')}/>
                    }
                    {
                        !(isMyNft || tokens?.length === 0) &&
                        <Pagination className={'pagination'} tokensLength={dataStore?.totalNft}/>
                    }
                </LoadWrapper> */}
            {/* </div> */}
        </MarketplaceStyled>
    );
});

export default MarketplacePage;
