import nftAbi from "./abi/nft_abi.json";
import marketAbi from "./abi/market_abi.json";
import erc20Abi from "./abi/erc20_abi.json";
import {AbiItem} from "web3-utils";

export const InputType = {
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
}

export const ContractAddress = {
    MARKET_CONTRACT: '0x012d593aEE466dee45597c6CFb3EAE7d24180146',
    NFT_CONTRACT: '0x7ad3f19C2068CE75ADE105af5676aF35383F51Ca',
    ERC20_CONTRACT: '0x96f3e58F6eDAaBB49216C2dC1fd3F97266072722'
}

export const ContractAbi = {
    NFT_ABI: nftAbi as AbiItem[],
    MARKET_ABI: marketAbi as AbiItem[],
    ERC20_ABI: erc20Abi as AbiItem[],
}

export const PRICE_DECIMALS = 1000000000000000000;
