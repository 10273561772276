import {createGlobalStyle} from 'styled-components';
import DosisExtraBoldWoff from '../fonts/Dosis-ExtraBold.woff'
import DosisExtraBoldWoff2 from '../fonts/Dosis-ExtraBold.woff2'
import DosisMediumWoff from '../fonts/Dosis-Medium.woff'
import DosisMediumWoff2 from '../fonts/Dosis-Medium.woff2'
import PoppinsRegularWoff from '../fonts/Poppins-Regular.woff'
import PoppinsRegularWoff2 from '../fonts/Poppins-Regular.woff2'
import PressStartWoff from '../fonts/Press-Start-2P.woff'
import PressStartWoff2 from '../fonts/Press-Start-2P.woff2'
// import bg from "../images/backgrounds/lines.png"
export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Dosis';
    src: local('Dosis ExtraBold'),
    url(${DosisExtraBoldWoff}) format('woff'),
    url(${DosisExtraBoldWoff2}) format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Dosis';
    src: local('Dosis Medium'),
    url(${DosisMediumWoff}) format('woff'),
    url(${DosisMediumWoff2}) format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'),
    url(${PoppinsRegularWoff}) format('woff2'),
    url(${PoppinsRegularWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Press Start 2P';
    src: local('Press Start 2P'),
    url(${PressStartWoff}) format('woff'),
    url(${PressStartWoff2}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  
  html {
    font-family: 'Dosis', 'Arial', sans-serif;
    font-weight: 500;
    background-blend-mode: normal;
    background-repeat: no-repeat;
    background-color: #23232f;
    min-height: 100vh;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    max-width: 100%;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    overflow-x: hidden;
  }

  h2 {
    font-size: 50px;
    font-weight: 800;
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
    letter-spacing: 0.035em;
    color: #ffffff;
  }

  .visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    white-space: nowrap;
    border: 0;
    clip-path: inset(100%);
  }

  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .container {
    max-width: 1920px;
    margin: 0 auto;
  }
  
  .characteristics-title {
    color: #525f6e;
  }

  .characteristics-number-field {
    background: linear-gradient(90deg, #2E2E3C -80%, #23232F 85.82%);
    border-radius: 5px;
    padding: 7px;
    text-align: center;
  }

  button,
  label {
    cursor: pointer;
  }

  button[disabled] {
    cursor: unset;
  }
  
  * {
    min-width:0;
  }
`;
