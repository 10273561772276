import React from 'react';
import {ContentTwoStyled} from "./ContentTwo.styled";

import ship2 from "../../images/ship2.png"
import shipMobile from "../../images/ship-2-mobile.png"
import AnimatedImg from "../AnimatedImg/AnimatedImg";
import classNames from "classnames";


const ContentTwo: React.FC = () => {
    return (
        <ContentTwoStyled className="staking">
            <img className='ship-img' src={ship2} />
            <div className="right">
                <h2>Explore 2 <span className="violet">earn</span></h2>
                <div className="ship-img-wrapper">
                    <img className="ship-img-mobile" src={shipMobile} />
                </div>
                <p>
                    Even before launch each token holder will achieve all benefits and features of DeFi side of LIMINAL SPACE projects. Send your NFT ship to this unknown galaxy to explore and earn.
                </p>
            </div>
        </ContentTwoStyled>
    );
};

export default ContentTwo;
