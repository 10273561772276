import React from 'react';
import Spinner from "../UIcomponents/Spinner/Spinner";

interface Props {
    isDataLoaded: boolean,
    children: React.ReactNode,
    Loader?: React.ReactNode,
}

// @ts-ignore
const LoadWrapper: React.FC<Props> = ({isDataLoaded, children}: Props) => {
    return (
        (isDataLoaded && children) || ''
    );
};

export default LoadWrapper;
