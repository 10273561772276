import styled from "styled-components";
import arrowSvg from "../../images/svg/arrow-right.svg";

export const PaginationStyled = styled.div`
    display: flex;
  
  .list {
    display: flex;
  }
  
  .item {
    font-weight: 800;
    line-height: 24px;
    color: white;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  .nav-button {
    padding: 5px;
    border-radius: 5px;
    outline: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
      margin: auto;
    }
  }
  
  .next {
    margin-left: 15px;
  }
  
  .prev {
    transform: rotate(180deg);
    margin-right: 15px;
  }
  
  .item.active {
    border-radius: 5px;
    background: #454558;
  }
`;