import React from 'react';
import {TypesOfNFTStyled} from "./TypesOfNFT.styled";
import ship from "../../images/ship.png"
import detail from "../../images/detail.png"
import secret from "../../images/secret.png"
import {Icon} from "../UIcomponents/Icons/icons";
import SliderTypes from "../SliderTypes/SliderTypes";

const TypesOfNFT: React.FC = () => {
    return (
        <TypesOfNFTStyled>
            <h2>Types of NFT</h2>
            <SliderTypes />
            {/*<ul className="types__list">*/}
            {/*    <li className="types__item">*/}
            {/*        <span>Phase 1</span>*/}
            {/*        <div className="types__card types__card--spaceships">*/}
            {/*            <img src={ship}/>*/}
            {/*            <h3>Spaceships</h3>*/}
            {/*            <p>Main collection with uniq 5000 spaceships</p>*/}
            {/*            <a href="#marketplaceBlock">Read more<Icon classname="arrow-right" icon="arrowRight" /></a>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li className="types__item">*/}
            {/*        <span>Phase 2</span>*/}
            {/*        <div className="types__card types__card--details">*/}
            {/*            <img src={detail}/>*/}
            {/*            <h3>Details</h3>*/}
            {/*            <p>Upgrade your spaceships</p>*/}
            {/*            <a>Coming soon...</a>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*    <li className="types__item">*/}
            {/*        <span>Season 3</span>*/}
            {/*        <div className="types__card types__card--secret">*/}
            {/*            <img src={secret}/>*/}
            {/*            <h3>Secret type</h3>*/}
            {/*            <p>Most secret part</p>*/}
            {/*            <a>Coming soon...</a>*/}
            {/*        </div>*/}
            {/*    </li>*/}
            {/*</ul>*/}
        </TypesOfNFTStyled>
    );
}

export default TypesOfNFT;
