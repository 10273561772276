import React, {useEffect, useState} from 'react';
import {ConnectWalletBtnStyled, LinkButton} from "./ConnectWalletBtn.styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {ModalStore, WalletStore} from "../../stores";
import {ModalsEnum} from "../../stores/ModalStore";
import Web3 from "web3";
import Web3Store from "../../stores/Web3Store";
import logo from '../../images/svg/logo.svg'
import wallet from '../../images/svg/Union.svg'
import menu from '../../images/menu.png'
import { Link } from 'react-router-dom';
import {icons} from "../UIcomponents/Icons/icons";
import {Icon} from "../UIcomponents/Icons/icons";
import classNames from "classnames";

type Props = {
    page?: string,
}

const ConnectWalletBtn: React.FC<Props> = observer(({page}: Props) => {
    const modalStore = useInjection(ModalStore);
    const walletStore = useInjection(WalletStore);
    const web3Store = useInjection(Web3Store);
    const [open, setOpen] = useState(false);
    const isAuthorized = walletStore?.connected;
    const isConnecting = walletStore?.isConnecting;
    const accountId = web3Store?.account

    return (
        <ConnectWalletBtnStyled >
            <Link className="logo__wrapper" to="/">
                <div className="logo">
                    <Icon classname='logo-icon' icon="logo"/><span className="logo-text">Liminal space</span>
                </div>
            </Link>
            <div className='header'>
                <Link to="/" style={{textDecoration:'none'}}><LinkButton active={page==="Home"}>HOME<Icon classname="nav-icon" icon="home" /></LinkButton></Link>
                <Link to="/game" style={{textDecoration:'none'}}><LinkButton disabled active={page==="Game"}>PLAY GAME<Icon classname="nav-icon" icon="play" /></LinkButton><span className="soon">soon</span></Link>
                <Link to="/marketplace" style={{textDecoration:'none'}}><LinkButton disabled active={page==="Market"}>MARKETPLACE<Icon classname="nav-icon" icon="marketplace" /></LinkButton><span className="soon">soon</span></Link>
                <Link to="/mint" style={{textDecoration:'none'}}><LinkButton active={page==="Mint"}>MINT<Icon classname="nav-icon" icon="mint" /></LinkButton></Link>
                <div className="more" style={{textDecoration:'none'}}><LinkButton>MORE<Icon classname="nav-icon nav-icon--last" icon="more" /></LinkButton>
                    <div className="dropdown-wrapper">
                        <ul className="dropdown__list">
                            <li className="dropdown__item">
                                <a href="https://twitter.com/0xLiminalSpace" target="_blank">Twitter</a>
                            </li>
                            <li className="dropdown__item">
                                <a href="https://discord.com/channels/986653891422740530/992508169634988092" target="_blank">Discord</a>
                            </li>
                            <li className="dropdown__item">
                                <a href="">Wiki</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {
                isAuthorized
                    ? <button
                        onClick={() => {
                            walletStore.disconnect();
                        }}
                        className="connect-wallet">
                        Disconnect
                    </button>
                    :
                    <button
                        onClick={() => {
                            walletStore.connectWallet();
                        }}
                        className={classNames("connect-wallet", "button-disabled")}>
                        {/*TODO pointer-events: none*/}
                        {/*TODO filter: greyscale(0.5);*/}
                        {/*TODO cursor: default*/}
                        Connect Wallet
                        <img src={wallet}/>
                    </button>
            }
        <div
          className="mobile__menu"
          onClick={() => {
              if (open === true) {
                  setOpen(false);
              } else {
                  document.body.style.overflowY = "hidden";
                  setOpen(true);
              }
          }}
        >
            <Icon classname="mobile-menu-icon" icon="mobileMenu" />
        </div>
        <div
            className="mobile__menu__open"
            style={{ display:open ? "flex" : "none" }}
            onClick={() => {
            setOpen(false);
            // document.body.style.overflowY = "scroll";
            }}
        >
            {
                isAuthorized
                    ? <button
                        onClick={() => {
                            walletStore.disconnect();
                        }}
                        className="connect-wallet">
                        Disconnect
                    </button>
                    :
                    <button
                        onClick={() => {
                            walletStore.connectWallet();
                        }}
                        className={classNames("connect-wallet", "button-disabled")}>
                        {/*TODO pointer-events: none*/}
                        {/*TODO filter: greyscale(0.5);*/}
                        {/*TODO cursor: default*/}
                        Connect Wallet
                        <Icon classname={classNames("nav-icon", "nav-icon--wallet")} icon="wallet" />
                    </button>
            }
            <Link className="mobileMenuItem" to="/">HOME<Icon classname={classNames("nav-icon", "nav-icon--home")} icon="home" /></Link>
            <Link className="mobileMenuItem" to="/game">PLAY GAME<Icon classname={classNames("nav-icon", "nav-icon--play")} icon="play" /></Link>
            <Link className="mobileMenuItem" to="/marketplace">MARKETPLACE<Icon classname={classNames("nav-icon", "nav-icon--marketplace")} icon="marketplace" /></Link>
            <Link className="mobileMenuItem" to="/mint">MINT<Icon classname={classNames("nav-icon", "nav-icon--mint")} icon="mint" /></Link>
            <a href="#footerBlock" className={classNames("more", "mobileMenuItem")}>CONTACTS<Icon classname={classNames("nav-icon", "nav-icon--more", "nav-icon--last")} icon="more" />
            </a>
        </div>
        </ConnectWalletBtnStyled>
    );
});

export default ConnectWalletBtn;
