import React, {useEffect} from 'react';
import {SuccessModalStyled} from "./SuccessModal.styled";
import {useInjection} from "inversify-react";
import AppStore from "../../stores/AppStore";
import {observer} from "mobx-react";

const SuccessModal: React.FC = observer(() => {
    const appStore = useInjection(AppStore);

    useEffect(() => {
    }, [appStore?.isSuccessModalShown]);

    return (
        <SuccessModalStyled className={(appStore?.isSuccessModalShown ? '' : 'hidden')}>
            <div className="modal-container">
                <h2 className="title">Bought <span className="title-label">successfully</span></h2>
                <p className="text">
                    Your token was bought successfully. It will appear on MyNft page soon.
                </p>
                <button
                    className="confirm-btn"
                    onClick={() => appStore.changeSuccessModalView(false)}
                >
                    <span className="confirm-btn-text">Okay</span>
                </button>
                <button
                    className="close-btn"
                    onClick={() => appStore.changeSuccessModalView(false)}
                >
                    <span className="visually-hidden">Close modal</span>
                </button>
            </div>
        </SuccessModalStyled>
    );
});

export default SuccessModal;
