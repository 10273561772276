import React, {useState} from 'react';
import {SellNftModalStyled} from "./SellNftModal.styled";
import {useInjection} from "inversify-react";
import AppStore from "../../stores/AppStore";
import {observer} from "mobx-react";
import {ContractAbi, ContractAddress} from "../../const";
import Web3Store from "../../stores/Web3Store";

const SellNftModal: React.FC = observer(() => {
    const appStore = useInjection(AppStore);
    const web3Store = useInjection(Web3Store);
    const tokenId = appStore?.tokenToSale;

    const [price, setPrice] = useState(null);

    const onSubmitHandler = async () => {
        const marketContract = await web3Store.createContract(ContractAbi.MARKET_ABI, ContractAddress.MARKET_CONTRACT);
        const nftContract = await web3Store.createContract(ContractAbi.NFT_ABI, ContractAddress.NFT_CONTRACT);
        await web3Store.createNftSale(nftContract, marketContract, tokenId, price);
        await appStore.changeSuccessSellModalView(true);
    }

    return (
        <SellNftModalStyled className={(appStore.isSellNftModalShown ? '' : 'hidden')}>
            <div className="modal-container">
                <h2 className="title">Sell your <span className="title-label">Nft</span></h2>
                <p className="text">
                    Your asset will be listed on the Marketplace with a fixed price.
                    To get it back, you will have to cancel the sale
                </p>
                <form
                    className="form"
                    action=""
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmitHandler();
                        appStore.changeSellNftModalView(false);
                    }}
                >
                    <div className="price">
                        <input
                            className="price-input"
                            type="number"
                            step={'any'}
                            name="price"
                            onChange={(e) => setPrice(e.target.value)}
                        />
                        <label className="price-label" htmlFor="price">Sell at</label>
                    </div>
                    <div className="disclaimer">
                        <input className="disclaimer-input visually-hidden" type="checkbox" name="disclaimer"/>
                        <label className="disclaimer-label" htmlFor="disclaimer">
                            Here comes a disclaimer about the risks the owner takes when
                            putting this NFT for sale. If there is any.
                        </label>
                    </div>
                    <button className="submit-btn" type="submit">
                        <span className="submit-btn-text">Confirm</span>
                    </button>
                </form>
                <button
                    className="close-btn"
                    onClick={() => appStore.changeSellNftModalView(false)}
                >
                    <span className="visually-hidden">Close modal</span>
                </button>
            </div>
        </SellNftModalStyled>
    )
        ;
});

export default SellNftModal;
