import styled from "styled-components";
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'

export const SliderTypesStyled = styled.div`
  //padding: 40px;
  position: relative;
  
  h2 {
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Dosis', sans-serif;
    margin-bottom: 44px;
  }
  
  h3 {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 51px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 52px 0;
  }
  
  .slider-types__list {
    max-width: 1021px;
    margin: 0 auto;
    overflow: visible;
    //padding: 0;
    //margin: 0 auto;
    //display: flex;
    //flex-wrap: wrap;
    //max-width: 1021px;
    //justify-content: space-between;
  }

  @media (max-width: 768px) {
    .slider-types__item {
      flex: 0 0 256px;
    }
  }


  
  .slider-types__list span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    display: block;
    letter-spacing: 0.035em;
    font-family: 'Dosis', sans-serif;
    color: #FFFFFF;
    margin-bottom: 19px;
  }
  
  .slider-types__card {
    background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
    border: 3px solid rgba(81, 81, 81, 0.3);
    box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    padding: 40px 16px 20px 16px;
    min-height: 505px;
  }
  
  .slider-types__card  img {
    display: block;
    margin: 0 auto 46px;
  }
  
  .slider-types__card p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.035em;
    font-family: 'Dosis', sans-serif;
    color: #898CA9;
    max-width: 255px;
    margin: 0 auto 53px auto;
  }
  
  .slider-types__card--details p,
  .slider-types__card--secret p {
    margin-bottom: 74px;
  }
  
  .slider-types__card--spaceships h3 {
    color: #6372FC;
  }
  
  .slider-types__card--spaceships p {
    margin-bottom: 48px;
  }

  .slider-types__card--details h3 {
    background: linear-gradient(225.91deg, #C63B3B 1.03%, #D174E0 77.89%, #70BAFF 141.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .slider-types__card--secret h3 {
    text-shadow: 0px 0px 18.5471px #FFFFFF, 0px 0px 10.5983px #FFFFFF, 0px 0px 6.18236px #FFFFFF, 0px 0px 3.09118px #FFFFFF, 0px 0px 0.883195px #FFFFFF, 0px 0px 0.441597px #FFFFFF;
  }
  
  .slider-types__card--spaceships a {
    width: fit-content;
    margin: 0 auto;
  }
  
  .slider-types__card--spaceships a:hover,
  .slider-types__card--spaceships a:focus {
    opacity: 0.6;
  }

  .slider-types__card--spaceships a:active {
    opacity: 0.4;
  }
  
  .slider-types__card a {
    color: #B982FF;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .arrow-right {
    margin-left: 6px;
  }
  
  @media (max-width: 1439px) {
    .slider-types__list {
      max-width: 946px;
    }
    
    .slider-types__list span {
      font-size: 15px;
      line-height: 23px;
    }
    
    .slider-types__card {
      padding: 37px 15px;
      min-height: unset;
    }
    
    .slider-types__card h3 {
      font-size: 32px;
      line-height: 40px;
    }

    .slider-types__card--spaceships p {
      margin-bottom: 50px;
    }
    
    .slider-types__card--spaceships img {
      width: 251px;
      height: 143px;
    }
    
    .slider-types__card--details img {
      width: 166px;
      height: 143px;
    }
    
    .slider-types__card--secret img {
      width: 92px;
      height: 143px;
    }
  }
  
  @media (max-width: 1023px) {
    .slider-types__card {
      padding: 32px 13px 29px;
    }
    
    .slider-types__card img {
      margin: 0 auto 40px;
    }
    
    .slider-types__card h3 {
      margin: 0 0 41px 0;
    }
    
    .slider-types__item span {
      display: none;
    }
    
    .slider-types__card--spaceships img {
      width: 217px;
      height: 124px;
    }
    
    .slider-types__card--spaceships p {
      margin-bottom: 46px;
    }

    .slider-types__card--details {
      img {
        width: 144px;
        height: 123px;
      }
      
      p {
        margin-bottom: 65px;
      }
    }

    .slider-types__card--secret {
      img {
        width: 79px;
        height: 124px; 
      }

      p {
        margin-bottom: 65px;
      }
    }

    .slider-types__card p {
      font-size: 12px;
      line-height: 18px;
      max-width: 211px;
    }

    .slider-types__card a {
      font-size: 12px;
    }
  }
`;
