import React from 'react';
import {EvolvingNFTStyled} from "./EvolvingNFT.styled";
import ship3 from '../../images/ship3.png'
import arrow from '../../images/arrow.svg'
import AnimatedImg from "../AnimatedImg/AnimatedImg";

const EvolvingNFT: React.FC = () => {
    return (
        <EvolvingNFTStyled>
            <div className="explore">
                <div className="left">
                    <h2>The evolving <span className="blue">&nbsp;nft </span>
                    </h2>
                    <p>
                        first wave of founders collectible NFTs in June,
                         raising $3 million. And now it will create NFT 
                         collectibles in partnership with the NRG Esports
                          to tap into the competitive gaming market (which
                           NFTs and blockchain haven’t turned to yet) 
                           and bolster fan engagement.
                    </p>
                   
                </div>
                </div>
        </EvolvingNFTStyled>
    );
};

export default EvolvingNFT;
