import React, {useEffect} from 'react';
import {ProductsItemStyled} from "./ProductsItem.styled";
import {ContractAbi, ContractAddress, PRICE_DECIMALS} from "../../const";
import {Web3Store} from "../../stores/Web3Store";
import {useInjection} from "inversify-react";
import {generatePath, useHistory} from "react-router-dom";
import SeasonLabel from "../UIcomponents/SeasonLabel/SeasonLabel";
import Rarity from "../UIcomponents/Rarity/Rarity";
import AppStore from "../../stores/AppStore";
import {observer} from "mobx-react";
import {useMediaQuery} from '../../utils/hooks';
import {WalletStore} from "../../stores";
import {marketplaceTokenInterface} from "../../Interfaces";
import { NftButton } from '../NftButton/NftButton';
import { RarityCircle } from '../RarityCircle/RarityCircle';
import { getRarityFromNumber } from '../../utils/utilities';

type Props = {
    product: marketplaceTokenInterface;
    isListView?: boolean
}

export const buyTokenHandler = async (store: Web3Store, tokenId, price, appStore, account) => {
    return new Promise(async (resolve, reject) => {

        const marketContract = await store.createContract(ContractAbi.MARKET_ABI, ContractAddress.MARKET_CONTRACT);
        const erc20Contract = await store.createContract(ContractAbi.ERC20_ABI, ContractAddress.ERC20_CONTRACT);

        return store
            .buyNft(marketContract, erc20Contract, tokenId, price, account)
            .then(() => resolve(1))
            .then(() => appStore?.changeSuccessModalView(true))
            .catch((err) => reject(err))
    })
}

export const cancelSell = async (store: Web3Store, tokenID, appStore) => {
    return new Promise(async (resolve, reject) => {
        const marketContract = await store.createContract(ContractAbi.MARKET_ABI, ContractAddress.MARKET_CONTRACT);

        return store
            .removeNftFromSale(marketContract, tokenID)
            .then(() => resolve(1))
            .catch((err) => reject(err))
    })
}

export const sellTokenHandler = async (store, tokenId, appStore) => {
    return new Promise((resolve, reject) => {
        appStore.changeSellNftModalView(true);
        appStore.selectTokenToSale(tokenId)
            .then(() => resolve(1))
            .catch((err) => reject(err));
    })
}


const ProductsItem: React.FC<Props> = observer(({product, isListView}: Props) => {
    const store = useInjection(Web3Store);
    const appStore = useInjection(AppStore);
    const walletStore = useInjection(WalletStore);
    const history = useHistory();
    const isMobile = !useMediaQuery('(min-width: 768px)');

    const account = store?.account;

    let pathName = '';

    // if (product.isMyNft) {
    //     pathName = generatePath(`mynft/${product.token[0].tokenId}`);
    // } else {
    //     pathName = generatePath(`/marketplace/${product.token[0].tokenId}`);
    // }

    // useEffect(() => {
    //     // console.log(product.isMyNft, 'my nft', product.token[0].saleId, 'sale id')
    //     // @ts-ignore
    // }, [window.location, product.token[0].tokenId])

    const renderData = () => {
        if (!product.isMyNft) {
            return (
                <>
                    {!isListView && <p className="product-name">{product.name}</p>}
                    <div className="bottom-info">
                        <div className="quantity">
                            {!isMobile && <p className="quantity-title characteristics-title">Quantity</p>}
                            <p className="quantity-number characteristics-number-field">
                                <span className="product-amount">{product?.amount}</span>
                                &nbsp;/&nbsp;
                                <span className="product-out-of">{product?.allAmount || 50}</span>
                            </p>
                        </div>
                        <div className="price">
                            {!isMobile && <p className="price-title characteristics-title">Price</p>}
                            <p className="price-number characteristics-number-field">{product.price/PRICE_DECIMALS} ETH</p>
                        </div>
                    </div>
                    {/* <button
                        className="buy-button"
                        onClick={(evt) => {
                            evt.stopPropagation();
                            buyTokenHandler(store, product?.token[0]?.saleId, product.price, appStore);
                        }}
                    >
                        <span className="buy-button-text">Buy now</span>
                    </button> */}
                    <div className="buy-button-container">
                        <NftButton
                            onClick={async () => buyTokenHandler(store, product?.token[0]?.saleId, product.price, appStore, account)}
                            disabled={!walletStore.connected || product?.owner === account}
                            text="Buy now"
                            className="buy-button"
                            textClassName="buy-button-text"
                        />
                    </div>
                </>
            )
        } else if (product.isMyNft && product.token[0].saleId) {
            return (
                <div className="data">
                    <p className="product-name">${product.name}</p>
                    <p className="for-sale-label">For Sale</p>
                </div>
            )
        } else {
            return (
                <p className="product-name">${product.name}</p>
            )
        }
    }


    return (
        <ProductsItemStyled
            className={product.isMyNft ? 'mynft' : ''}
            isListView={isListView}
            onClick={() => {
                history.push(pathName)
                location.reload()
            }}
        >
            <div className="product-info-wrapper">
                <div className="top-info">
                    <SeasonLabel season={product?.season || 'Season 1'}/>
                    <Rarity rarity={product?.rarity + 1} isMobile={isMobile}/>
                </div>
                <div className="product-img-wrapper">
                    <RarityCircle rarity={getRarityFromNumber(product.rarity)}/>
                    <img className="product-img"
                         src={product?.image}
                    />
                </div>
            </div>
            {
                renderData()
            }
        </ProductsItemStyled>
    );
});

export default ProductsItem;
