import styled from "styled-components";
import shipBg from "../../images/ship1-bg.png"
import {icons} from "../UIcomponents/Icons/icons";
import {Icon} from "../UIcomponents/Icons/icons";
import mainBgMobile from "../../images/bg-1-mobile.png"

export const ContentOneStyled = styled.div`
  margin-bottom: 40px;
  padding: 36px 40px 0;

  .ship-image {
    display: none;
  }
  
  .modalShow {
    display: block !important;
  }

  .grey-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 555;
    background: rgba(0, 0, 0, 0.25);
    display: block;

    .white-overlay {
      width: 50%;
      height: auto;
      background: #fff;
      border-radius: 15px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5555;
      padding: 25px;

      .cross {
        position: absolute;
        right: 5px;
        top: -10px;
      }

      .cross:after {
        cursor: pointer;
        font-size: 43px;
        display: inline-block;
        content: "\\00d7"; /* This will render the 'X' */
      }
    }
  }

  .buttons {
    display: flex;
  }

  .heading {
    font-size: 50px;
    margin-bottom: 48px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: "Dosis", sans-serif !important;
    
    .brake-mobile {
      display: none;
    }
  }
  
  .explore {
    @media (max-width: 767px) {
      padding: 0 40px;
    }
    
    position: relative;
    z-index: 1;
    max-width: 760px;
    //display: grid;
    //grid-template-columns: 50% 50%;

    button {
      cursor: pointer;
    }

    .left {
      width: 100%;
      margin: auto;
      padding-left: 20px;
      
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .ship-img-wrapper {
      display: none;
    }
    
    .ship-img {
      width: 100%;
      position: relative;
    }
    
    .image-container {
      position: relative;
      width: 432px;
      height: 432px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto auto auto 0;

      .image {
        position: relative;
        z-index: 2;
        margin: auto;
      }

      &:before {
        content: '';
        position: absolute;
        margin: auto;
        width: 336px;
        height: 336px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
      }
      
      &:after {
        position: absolute;
        content: '';
        width: 432px;
        height: 432px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        opacity: 0.5;
        filter: blur(200px);
        top: -20px;
        left: -20px;
        animation: lightup infinite;
        animation-duration: 4s;
      }
    }

    .left {
      height: auto;
      text-align: left;

      .circle-background {
        position: absolute;
        top: 230px;
        right: 10px;
        width: 550px;
      }

      .karakter {
        width: 300px;
        margin-right: -20px;
        position: absolute;
        z-index: 55;
        top: 200px;
        right: 150px;
      }

      button,
      a {
        display: block;
        text-decoration: none;
        height: 50px;
        border-radius: 10px;
        font-size: 17px;
        font-family: "Dosis", sans-serif !important;
        text-align: center;
        line-height: 50px;
        color: #fff;
        border: 0;
        font-weight: 800;
      }

      .bg-blue {
        background: linear-gradient(87.46deg, #3C4BD7 -3.04%, #6D83F3 133.03%, #6171FE 172.86%);
        width: 146px;
      }
      
      .bg-blue:hover,
      .bg-blue:focus {
        box-shadow: 0px 0px 10px 10px rgba(65,80,218, 0.2);
      }

      .bg-pink {
        background: linear-gradient(264.06deg, #FF0000 -68.99%, #D174E0 40.01%, #FF7070 130.88%);
        margin-left: 20px;
        width: 121px;
      }

      .bg-pink:hover,
      .bg-pink:focus {
        box-shadow: 0px 0px 10px 10px rgba(234,114,164, 0.2);
      }

      .arrow-right {
        margin-left: 12px;
        margin-bottom: 2px;
      }

      .green {
        display: inline-block;
        margin-left: -25px;
        color: #63FCA3;
        text-shadow: 0px 4px 25px rgba(113, 218, 180, 0.5);
      }
      .blue {
        color: #6372FC;
        text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5);
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 152.4%;
        color: #fff;
        font-family: "Dosis", sans-serif !important;
        width: 71%;
        margin-bottom: 50px;
      }
    }

    .circle-holder {
      width: auto;
      height: auto;
      margin-top: 220px;
      margin-left: 80px;
      position: relative;

      .big-circle {
        width: 336px;
        height: 336px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        position: relative;
        z-index: 5;
      }

      .big-circle-fade {
        width: 432px;
        height: 432px;
        border-radius: 50%;
        display: inline-block;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        opacity: 0.5;
        filter: blur(200px);
        position: absolute;
        top: -20px;
        left: -20px;
        animation: lightup infinite;
        animation-duration: 4s;
      }

      .circle-1 {
        position: absolute;
        top: -80px;
        margin-left: 220px;
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
      }

      .circle-2 {
        position: absolute;
        top: -20px;
        margin-left: 300px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        filter: blur(10px);
      }

      .circle-3 {
        position: absolute;
        top: 70px;
        margin-left: 360px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: linear-gradient(180deg, #3CC4D7 0%, #6DF3D3 54.53%, #61FEA0 100%);
        filter: blur(15px);
      }

      img {
        top: -30px;
        left: 70px;
        position: absolute;
        z-index: 5;
      }
    }

    @keyframes lightup {
      0% {
        filter: blur(200px);
      }
      50% {
        filter: blur(100px);
        opacity: 0.8;
      }
      100% {
        filter: blur(200px);
      }
    }
  }

  
  @media (max-width: 1439px) {
    display: grid;
    grid-template-columns: 59% 1fr;
    padding: 55px 24px 22px;
    margin-bottom: 0;
    
    .explore .left .button {
      font-size: 16px;
      line-height: 152%;
    }
    
    .explore .left p {
      width: 69%;
      margin-bottom: 31px;
    }
    
    .heading {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
    }
  }
  
  @media only screen and (max-width: 1023px) {
    position: relative;
    padding: 27px 0 22px;
    display: block;
    
    .explore .ship-img-wrapper {
      display: block;
      padding-left: 8px;
      margin: 0 auto 9px auto;
      max-width: 375px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: -300px;
        left: -175px;
        background-image: url('${mainBgMobile}');
        background-repeat: no-repeat;
        background-position-x: center;
        width: 862px;
        height: 914px;
        z-index: -1;
      }
    }
    
    .ship-image {
      display: block;
      width: 100%;
    }

    .heading {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 13px;
      position: relative;
      padding: 0 16px;
      
      .break-desktop-tablet {
        display: none;
      }
      
      .break-mobile {
        display: inline;
      }
    }
    
    .explore {
      position: relative;
      width: 100%;
      padding: 0;
      max-width: unset;
    }

    .explore .left {
      padding: 0;

      p {
        width: 98%;
        margin: 0 auto 29px auto;
        text-align: center;
        padding: 0 16px;
      }
    }
    
    .buttons {
      width: fit-content;
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 800px) {
    .white-overlay {
      width: 87% !important;
    }

    .explore {
      height: auto;
      grid-template-columns: 1fr;
    }

    .explore .left {
      width: 100%;
      margin-bottom: 35px;
      
      h2 {
        margin-left: 0;
      }
    }
  }
`;
