import {Container} from "inversify";
import {UserStore} from "./UserStore";
import {ModalStore} from "./ModalStore";
import {WalletStore} from "./WalletStore";
import {Web3Store} from "./Web3Store";
import {DataStore} from "./DataStore";
import AppStore from "./AppStore";
import FilterStore from "./FilterStore";

// @ts-ignore
export class HistoryStore implements History {
}

export class RootStore {
    public userStore: UserStore;
    public container: Container;
    public modalStore: ModalStore;
    public walletStore: WalletStore;
    public web3Store: Web3Store;
    public dataStore: DataStore;
    public appStore: AppStore;
    public filterStore: FilterStore;

    public constructor() {
        this.userStore = new UserStore(this);
        this.modalStore = new ModalStore(this);
        this.walletStore = new WalletStore(this);
        this.web3Store = new Web3Store(this);
        this.dataStore = new DataStore(this);
        this.appStore = new AppStore(this);
        this.filterStore = new FilterStore(this);
        this.container = new Container();
        this.container.bind(UserStore).toConstantValue(this.userStore);
        this.container.bind(ModalStore).toConstantValue(this.modalStore);
        this.container.bind(WalletStore).toConstantValue(this.walletStore);
        this.container.bind(Container).toConstantValue(this.container);
        this.container.bind(Web3Store).toConstantValue(this.web3Store);
        this.container.bind(DataStore).toConstantValue(this.dataStore);
        this.container.bind(AppStore).toConstantValue(this.appStore);
        this.container.bind(FilterStore).toConstantValue(this.filterStore);
    }
}