import React from 'react';
import {ThemeProvider} from 'styled-components';

const theme = {
    colors: {
        primaryText: '#ffffff',
        secondaryText: '#2c3e50',
        accent: '#63fca3',
        warning: '#e10050',
        warningLight: '#f16969',
        background: '#23232f',
        border: '#454558',
    },
    gradients: {
        cold: 'linear-gradient(87.46deg, #3CC4D7 -3.04%, #6DF3D3 92.88%, #61FEA0 172.86%)',
        hot: 'linear-gradient(264.06deg, #C63B3B -68.99%, #D174E0 40.01%, #70BAFF 130.88%)',
    }
};

export const Theme: React.FC = ({children}) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
