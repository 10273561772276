import styled from "styled-components";

export const EvolvingNFTStyled = styled.div`
  padding: 40px;
  margin-bottom: 140px;
  .row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 120px;
  }
  .row_item {
    margin-right: 20px;
    margin-top: 20px;
  }
  .text {
    display: flex;
    margin-top: 32px;
    justify-content: center;
  }
  p {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 26px;
    text-align: center;
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 45px;
    }
  }
  h2 {
    text-align: center;
    font-size: 52px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: "Dosis", sans-serif !important;
    @media (max-width:700px) {
      text-align: center;
    }
  }
  .blue {
    color: #6372fc;
    text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5);
  }
`;
