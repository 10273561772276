import styled from "styled-components";
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'

export const TypesOfNFTStyled = styled.div`
  padding: 40px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 19px;
    right: 228px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: top right;
    width: 74px;
    height: 74px;
  }

  &::after {
    position: absolute;
    content: '';
    top: 66px;
    right: 115px;
    background-image: url(${starSmall});
    background-repeat: no-repeat;
    background-position: top right;
    width: 54px;
    height: 46px;
  }
  
  h2 {
    font-size: 50px;
    line-height: 63px;
    text-align: center;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Dosis', sans-serif;
    margin-bottom: 44px;
  }
  
  @media (max-width: 1439px) {
    &::before {
      background-size: contain;
      width: 52px;
      height: 52px;
      top: -6px;
      right: 164px;
    }
    
    &::after {
      background-size: contain;
      width: 38px;
      height: 33px;
      top: 7px;
      right: 82px;
    }
    
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  
  @media (max-width: 1023px) {
    padding: 40px 16px;
    
    &::before,
    &::after {
      display: none;
    }
  }
`;
