import {makeObservable, observable, runInAction} from "mobx";
import {RootStore} from "./RootStore";

interface filterInterface {
    item: string,
    value: string,
}

export class FilterStore {
    public rarityFilter: string[] = [];
    public seasonFilter: string[] = [];
    public replicasFilter: string[] = [];
    public typesFilter: string[] = ['All'];
    public sortType: string[] = [];
    public search: string[] = [];
    public saleFilter: string[] = [];
    public floorsFilter: string[] = [];
    public floorTilesFilter: string[] = [];

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this, {
            rarityFilter: observable,
            seasonFilter: observable,
            replicasFilter: observable,
            typesFilter: observable,
            sortType: observable,
            search: observable,
            saleFilter: observable,
            floorsFilter: observable,
            floorTilesFilter: observable,
        })
    }

    setFilter(filterName, filterValue: string) {
        // if radio button, only one item can be in array
        if (
            filterName === this.seasonFilter ||
            (filterName === this.typesFilter) ||
            (filterName === this.sortType) ||
            (filterName === this.search) ||
            (filterName === this.saleFilter) ||
            (filterName === this.floorsFilter) ||
            (filterName === this.floorTilesFilter)
        ) {
            runInAction(() => {
                filterName[0] = filterValue;
            })
        } else {
            runInAction(() => {
                filterName.push(filterValue);
            })
        }
    }

    removeFilter(filterName, filterValue: filterInterface) {
        runInAction(() => {
            const index = filterName.findIndex((item) => item === filterValue);
            filterName.splice(index, 1);
        })
    }

    setSearch(text) {
        runInAction(() => {
            this.search = text;
        })
    }

    resetStore() {
        runInAction(() => {
            this.rarityFilter = [];
            this.seasonFilter = [];
            this.replicasFilter = [];
            this.typesFilter = ['All'];
            this.sortType = [];
            this.search = [];
            this.saleFilter = [];
            this.floorsFilter = [];
            this.floorTilesFilter = [];
        })
    }
}

export default FilterStore;
