import React from 'react';
import {TeamStyled} from "./Team.styled";
import photoBG from '../../images/bgphoto.svg';
import ceo from '../../images/advisors/ceo.png';
import dev from '../../images/advisors/dev.png';
import cof from '../../images/advisors/cof.png';
import teamLogo from '../../images/team-logo.png';
import wallet from '../../images/svg/Union.svg';

const Team: React.FC = () => {
    return (
        <TeamStyled>
            <div className="team__heading">
                <h2><span className="blue">Another.dev </span>team</h2>
            </div>
            <p>
                Another.DEV is an another level blockchain development studio with deep expertise in technology, design and art. Having massive experience in different areas from creative copywriting, narrative gamedesign to blockchain wizardry, we offer pure gems of product
            </p>
            {/*<div className="team-overview">*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src={photoBG}/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src={ceo}/>*/}
            {/*        </div>*/}
            {/*        <div className="team-item-info">*/}
            {/*            <span className="name">team #1</span>*/}
            {/*            <span className="title">CEO</span>*/}
            {/*        </div>*/}
            {/*        /!* <div className="socials">*/}
            {/*            <a  target="_blank" className="linkedin">*/}
            {/*                <img src={linkedInImg}/>*/}
            {/*            </a>*/}
            {/*        </div> *!/*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src={photoBG}/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src={cof}/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Team #2</span>*/}
            {/*        <span className="title">CO-FOUNDER</span>*/}
            {/*        /!* <div className="socials">*/}
            {/*            <a href="https://www.linkedin.com/in/bram-hoogenkamp-a51a40b5/" target="_blank" className="linkedin">*/}
            {/*                <img src={linkedInImg}/>*/}
            {/*            </a>*/}
            {/*        </div> *!/*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src={photoBG}/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src={dev}/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Team #3</span>*/}
            {/*        <span className="title">Developer</span>*/}
            {/*        /!* <div className="socials">*/}
            {/*            <a  target="_blank" className="linkedin">*/}
            {/*                <img src={linkedInImg}/>*/}
            {/*            </a>*/}
            {/*        </div> *!/*/}
            {/*    </div>*/}

            {/*</div>*/}
            {/*<h2 style="margin-top: 150px;">The <br/><span className="green robotic">Advisors</span></h2>*/}
            {/*<div className="team-overview">*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src="../../assets/team_photo_2.png"/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src="../../assets/advisors/faouzi.jpg"/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Faouzi</span>*/}
            {/*        <span className="title">Owner DutchGameStudio</span>*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src="../../assets/team_photo_2.png"/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src="../../assets/advisors/zowie.jpg"/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Zowie</span>*/}
            {/*        <span className="title">CTO Bux Crypto</span>*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src="../../assets/team_photo_2.png"/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src="../../assets/advisors/sten.jpg"/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Sten</span>*/}
            {/*        <span className="title">Blockchain Marketing Firm Owner</span>*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src="../../assets/team_photo_2.png"/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src="../../assets/advisors/ian_ferrum.jpeg"/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Ian</span>*/}
            {/*        <span className="title">Ferrum Co-Founder & COO</span>*/}
            {/*    </div>*/}
            {/*    <div className="team-item">*/}
            {/*        <img className="bg-image" src="../../assets/team_photo_2.png"/>*/}
            {/*        <div className="circle">*/}
            {/*            <img className="person_photo" src="../../assets/advisors/Shashwat.jpeg"/>*/}
            {/*        </div>*/}
            {/*        <span className="name">Shashwat</span>*/}
            {/*        <span className="title">Owner AltcoinBuzz & Partner Enjinstarter</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </TeamStyled>
    );
};

export default Team;
