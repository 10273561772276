import styled from "styled-components";

interface Props {
  isMobile?: boolean
}

export const RarityStyled = styled.div<Props>`
  min-width: 25px;


  ${({isMobile}) => isMobile ? `
    align-self: center;
  `: ``}

  .rarity-levels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .rarity-title {
    margin-bottom: 10px;
    text-align: center;
  }

  .rarity-level {
    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }

    &:before {
      bottom: -20px;
      left: 0;
      display: block;
      width: 10px;
      height: 10px;
      content: "";
      border-radius: 50%;
      background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
      border: 1px solid #1d1d23;
    }
  }

  .rarity-1 {
    .rarity-level:first-child:before {
      background: white;
      border: 1px solid white;
    }
  }

  .rarity-2 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before {
      background: #63FCA3;
      border: 1px solid #3D9865;
    }
  }

  .rarity-3 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before,
    .rarity-level:nth-child(3):before {
      background: linear-gradient(132.71deg, #3F3CD7 -12.6%, #F17969 112.6%);
      border: 1px solid #9b4f44;
    }
  }

  .rarity-4 {
    .rarity-level:first-child:before,
    .rarity-level:nth-child(2):before,
    .rarity-level:nth-child(3):before,
    .rarity-level:nth-child(4):before {
      background: linear-gradient(132.71deg, #F1CB69 -12.6%, #F17969 112.6%);
      border: 1px solid #9b4f44;
    }
  }

  .rarity-5 {
    .rarity-level:before {
      background: linear-gradient(180deg, #C63B3B 0%, #EE7F7F 100%);
      border: none;
    }
  }
`;