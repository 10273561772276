import {makeObservable, observable, runInAction} from "mobx";
import axios from "axios";
import {apiUrl} from "../config";
import {RootStore} from "./RootStore";
import {getFullUrl} from "../utils/utilities";
import {MAX_PER_PAGE} from "../components/Pagination/Pagination";
import {activeTokenInterface, marketplaceTokenInterface} from "../Interfaces";


export class DataStore {
    public marketplaceTokens: marketplaceTokenInterface[];
    public myNfts: marketplaceTokenInterface[];
    public activeToken: activeTokenInterface;
    public isMarketplaceTokensLoaded: boolean = false;
    public isMyNftsLoaded: boolean = false;
    public isActiveTokenLoaded: boolean = false;
    public totalNft: number;

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this, {
            marketplaceTokens: observable,
            myNfts: observable,
            activeToken: observable,
            isMarketplaceTokensLoaded: observable,
            isMyNftsLoaded: observable,
            isActiveTokenLoaded: observable,
            totalNft: observable,
        })
    }

    async getTokens(page: number, filters?) {
        let url = `${apiUrl}api/v1/tokens/?page=${page}&&limit=${MAX_PER_PAGE}`;
        let isInitialFilter = false;

        if (filters?.rarityFilter.length) {
            url = getFullUrl(filters?.rarityFilter, 'rarity', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.seasonFilter.length) {
            url = getFullUrl(filters?.seasonFilter, 'season', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.replicasFilter.length) {
            url = getFullUrl(filters?.replicasFilter, 'replicas', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.typesFilter.length) {
            if (filters?.typesFilter[0] === 'All') {
                url = url;
            } else {
                url = getFullUrl(filters?.typesFilter, 'type', url, isInitialFilter);
            }
            isInitialFilter = false;
        }

        if (filters?.floorsFilter.length) {
            url = getFullUrl(filters?.floorsFilter, 'floor', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.floorTilesFilter.length) {
            url = getFullUrl(filters?.floorTilesFilter, 'floorTiles', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.sortType.length) {
            url = getFullUrl(filters?.sortType, 'sortBy', url, isInitialFilter);
        }

        if (filters?.search.length) {
            url = getFullUrl(filters?.search, 'searchBy', url, isInitialFilter);
        }

        const data = await axios.get(url)
        runInAction(() => {
            this.marketplaceTokens = data.data.data;
            this.isMarketplaceTokensLoaded = true;
            this.totalNft = data.data.count;
        })
    }

    async getActiveToken(id: number) {
        const data = await axios.get(`${apiUrl}api/v1/tokens/${id}`)
        runInAction(() => {
            this.activeToken = data.data;
            this.isActiveTokenLoaded = true;
        })
    }

    // adds label isMyNft to every item, if request comes not from SelectedProduct(isMoreNft)
    async getMyTokens(accountId: string, isMoreNft?: boolean, filters?) {
        let url = `${apiUrl}api/v1/user/${accountId}/tokens`;
        let isInitialFilter = true;

        if (filters?.saleFilter.length) {
            url = getFullUrl(filters?.saleFilter, 'sale', url, isInitialFilter);
            isInitialFilter = false;
        }

        if (filters?.typesFilter.length) {
            url = getFullUrl(filters?.typesFilter, 'type', url, isInitialFilter);
        }

        const data = await axios.get(url);
        if (!isMoreNft) {
            runInAction(() => {
                this.myNfts = data.data.data.map((item) => {
                    return {...item, isMyNft: true}
                })
                this.isMyNftsLoaded = true;
            })
        } else {
            runInAction(() => {
                this.myNfts = data.data.data;
                this.isMyNftsLoaded = true;
            })
        }
    }

    async getTotalLength() {
        const data = await axios.get(`${apiUrl}api/v1/tokens/tokens/count`);
        runInAction(() => {
            this.totalNft = data.data.count;
        })
    }
}

export default DataStore;
