import { Rarities } from "src/utils/utilities";
import styled from "styled-components";

export type RarityCircleProps = {
    rarity: Rarities
}

const gradientMap: Record<RarityCircleProps['rarity'], string> = {
    common: 'white',
    uncommon: '#63FCA3',
    rare: '#F17969',
    epic: '#F1CB69',
    legendary: '#C63B3B',
}

export const RarityCircleStyled = styled.div<RarityCircleProps>`
        position: absolute;
        border-radius: 50%;
        width: 75%;
        height: auto;
        padding-top: min(75%, 150px);
        margin: 0 auto;
        margin-top: -10px;
        left: 0;
        right: 0;
        max-width: 150px;
        max-height: 150px;
        filter: drop-shadow(3px 3px 15px ${({rarity}) => gradientMap[rarity]});
        border: 2px solid ${({rarity}) => gradientMap[rarity]};
`