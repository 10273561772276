import styled from "styled-components";
import footerBg from "../../images/footer-bg.png"

export const FooterStyled = styled.div`
  padding: 22px 40px 40px 40px;
  position: relative;
  background-image: url(${footerBg});
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;

  .footer__about {
    max-width: 1149px;
    background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
    border: 3px solid rgba(81, 81, 81, 0.3);
    border-radius: 25px;
    margin: 0 auto 260px;
    padding-top: 10px;
    padding-bottom: 38px;
    
    & a {
      display: block;
      width: fit-content;
      margin: 0 auto;
    }
  }
  
  .footer__about a:hover,
  .footer__about a:focus {
    opacity: 0.6;
  }

  .footer__about a:active {
    opacity: 0.4;
  }

  .logo__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 39px;

    img {
      display: block;
      width: 143px;
      height: 124px;
    }

    span {
      font-family: 'Dosis', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 34px;
      color: #FFFFFF;
    }
  }

  a {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #B982FF;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .arrow-right {
    margin-left: 6px;
  }
  
  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    margin-left: 5vw ;
    margin-bottom: 76px;
    font-family: 'Dosis', sans-serif !important;
  }

  .pink {
    background: linear-gradient(264.06deg, #FF0000 -68.99%, #D174E0 40.01%, #FF7070 130.88%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
    text-shadow: 0px 4px 25px #EB72A1;
  }
  
  .social__list {
    display: flex;
    padding: 0;
    max-width: 234px;
    margin: 0 auto 29px;
    align-items: center;
    justify-content: space-between;
  }
  
  .social__item {
    //margin-right: 24px;
    
    &:last-child {
      margin-right: 0;
    }
    
    &:hover,
    &:focus {
      opacity: 0.6;
    }
    
    &:active {
      opacity: 0.4;
    }
  }
  
  .copyright {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18.7354px;
    line-height: 28px;
    color: #ABABAB;
    text-align: center;
    display: block;
    width: fit-content;
    margin: 0 auto;
    padding-right: 10px;
  }
  
  @media (max-width: 1439px) {
    h2 {
      font-size: 32px;
      line-height: 40px;
    }
    
    .copyright {
      font-size: 16px;
      line-height: 24px;
    }
  }
  
  @media (max-width: 1023px) {
    padding: 20px 9px 20px 16px;
    
    h2 {
      margin-left: 0;
    }
    
    .footer__about {
      margin: 0 auto 100px;
    }
  }
  
`;
