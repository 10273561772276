import styled from "styled-components";
import bg2 from "../../images/backgrounds/bg2section.png"
import ship2 from "../../images/ship2.png"
import circlesTop from "../../images/circles-top.png"
import circlesBottom from "../../images/circles-bottom.png"
import secondBgMobile from "../../images/bg-2-mobile.png";
import secondBgShip from "../../images/ship-2-mobile.png";

export const ContentTwoStyled = styled.div`
  margin-bottom: 105px;
  padding-top: 43px;
  width: 100%;
  display: grid;
  grid-template-columns: 54% 1fr;
  position: relative;
  z-index: 1;
  top: 0;

  .mobile-show {
    display: none;
  }
  
  .ship-img {
    width: 100%;
    max-width: 773px;
    max-height: 794px;
    }
  
  .ship-img-wrapper {
    display: none;
  }
  
  .right {
    padding-top: 281px;
    padding-left: 8px;
    margin-bottom: auto;
    width: 100%;
    max-width: 598px;
  }

  .left,
  .right {
    height: auto;
    text-align: left;

    .button-holder {
      width: auto;
      height: auto;
      float: left;
      margin-left: 33px;
      margin-top: 20px;
    }

    button {
      width: 150px;
      height: 40px;
      line-height: 40px;
      background: linear-gradient(87.46deg, #3CC4D7 -3.04%,
      #6DF3D3 92.88%, #61FEA0 172.86%);
      box-shadow: 0px 4px 25px rgb(113 218 180 / 50%);
      color: #fff;
      border-radius: 10px;
      border: 0;
      position: relative;
      margin-right: 15px;
      margin-bottom: 15px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      .android {
        filter: brightness(0) invert(1);
        width: 20px;
      }
      p {
        line-height: 10px;
      }
      img {
        position: absolute;
        width: 16px;
        top: 9px;
        left: 10px;
      }

      .comming-soon {
        position: absolute;
        left: 40px;
        font-size: 9px;
        color: #fff;
        top: 10px;
      }

      .title {
        position: absolute;
        left: 40px;
        color: #fff;
        top: -3px;
        font-size: 16px;
      }
    }

    h2 {
      font-size: 50px;
      line-height: 63px;
      font-weight: 800;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Dosis', sans-serif !important;
      margin-bottom: 27px;
    }

    .green {
      color: #63FCA3;
      text-shadow: 0 4px 25px rgba(113, 218, 180, 0.5);
    }
    
    .violet {
      background: linear-gradient(264.06deg, #FF0000 -68.99%, #D174E0 40.01%, #FF7070 130.88%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 0px 8px #E572B1;
    }
    
    .blue {
      color: #6372FC;
      text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5);
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      font-family: 'Dosis', sans-serif !important;
      width: 96%;
    }
  }

  .circle-holder {
    width: auto;
    height: auto;
    margin-top: 0px;
    margin-left: 80px;
    position: relative;

    .bottom-round {
      width: 330px;
      height: auto;
      overflow: hidden;
      border-bottom-left-radius: 165px;
      border-bottom-right-radius: 165px;
      position: absolute;
      top: -90px;
      left: 3px;
      z-index: 5;
      padding-top: 30px;
    }

    img {
      position: absolute;
      z-index: 10;
      left: 100px;
      top: 10px;
    }

    .big-circle {
      width: 336px;
      height: 336px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
      position: relative;
      z-index: 5;
    }

    .big-circle-fade {
      width: 432px;
      height: 432px;
      border-radius: 50%;
      display: inline-block;
      background: linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
      opacity: 0.5;
      filter: blur(200px);
      position: absolute;
      top: -20px;
      left: -20px;
      animation: lightup infinite;
      animation-duration: 4s;
    }

    .circle-1 {
      position: absolute;
      top: -80px;
      margin-left: 220px;
      width: 58px;
      height: 58px;
      border-radius: 50%;
      background: linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
    }

    .circle-2 {
      position: absolute;
      top: -60px;
      margin-left: 150px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
      filter: blur(10px);
    }

    .circle-3 {
      position: absolute;
      top: -10px;
      margin-left: 290px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background: linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
      filter: blur(15px);
    }

    @keyframes lightup {
      0% {
        filter: blur(200px);
      }
      50% {
        filter: blur(100px);
        opacity: 0.8;
      }
      100% {
        filter: blur(200px);
      }
    }
  }

  @media (max-width: 1439px) {
    padding-top: 0;
    margin-bottom: 0;
    
    .right {
      max-width: 440px;
      padding-top: 227px;
      padding-left: 58px;
      
      h2 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 23px;
      }
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .circle-holder {
      margin-left: 30px;
    }
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
    left: 0;
    grid-template-columns: 1fr;
    padding: 78px 0 77px;
    
    .right {
      padding: 0;
      max-width: unset;
    }
    
    .right h2 {
      width: unset;
      text-align: center;
      margin-bottom: 19px;
      padding:  0 16px;
    }
    
    .right p {
      width: 100%;
      text-align: center;
      padding:  0 16px;
    }

    .ship-img {
      display: none;
    }
    
    .ship-img-wrapper {
      display: block;
      position: relative;
      margin: 0 auto 9px auto;
      max-width: 375px;
      padding-left: 13px;
      
      &::before {
        position: absolute;
        content: '';
        top: -275px;
        left: -200px;
        background-image: url('${secondBgMobile}');
        background-repeat: no-repeat;
        background-position: center;
        width: 808px;
        height: 808px;
        z-index: -1;
      }
    }
    
    .ship-img-mobile {
      width: 100%;
    }
    
    .circle-holder img {
      width: 350px;
    }

    .circle-holder .bottom-round {
      top: -116px;
    }
  }

  //@media only screen and (max-width: 800px) {
  //  height: auto;
  //  grid-template-columns: 1fr;
  //
  //    padding: 20px!important;
  //
  //
  //  .right {
  //    width: 100%;
  //    order: -1;
  //    margin-bottom: 20px;
  //    
  //    h2 {
  //      margin-left: 0;
  //      width: 100%!important;
  //      font-size: 35px;
  //      line-height: 45px;
  //    }
  //    
  //    p {
  //      margin-left: 0;
  //      width: 100%;
  //    }
  //  }
  //
  //  .left {
  //    display: none;
  //    margin-bottom: 100px;
  //  }
  //
  //  .mobile-show {
  //    display: block !important;
  //  }
  //
  //  .right .robotic {
  //    font-size: 30px !important;
  //    line-height: 46px !important;
  //  }
  //}
`;
