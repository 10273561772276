import Web3 from "web3";
import {action, makeObservable, observable, runInAction} from "mobx";
import {AbiItem} from "web3-utils";
import {ContractAddress} from "../const";
import {RootStore} from "./RootStore";
import {NETWORKS_INFO} from "./WalletStore";

export class Web3Store {
    public web3: Web3;
    public account: string;
    public authType: 'infura' | 'metamask';
    private provider = window.ethereum;

    constructor(private readonly rootStore: RootStore) {
        makeObservable(this, {
                web3: observable,
                account: observable,
                authType: observable,
                initWeb3: action,
                createContract: action,
                buyNft: action,
                createNftSale: action,
                approveTokens: action,
            }
        )
    }

    initWeb3() {
        if (typeof this.provider !== undefined) {
            this.provider.request({method: 'eth_requestAccounts'})
                .then((accounts) => {
                    runInAction(() => {
                        this.account = accounts[0]
                    })
                })
                .then(() => this.web3 = new Web3(this.provider))
                .then(() => this.authType = 'metamask')
                .catch((error) => console.log(error));
        }
    }

    async initInfuraWeb3() {
        this.web3 = new Web3(new Web3.providers.HttpProvider('https://speedy-nodes-nyc.moralis.io/69cb88ee10b051564cb2c142/bsc/testnet'));
        this.authType = 'infura';
    }

    async createContract(contractAbi: AbiItem[], contractAddress: string) {
        return new this.web3.eth.Contract(
            contractAbi as AbiItem[],
            contractAddress
        );
    }

    async buyNft(marketContract, erc20Contract, saleId: number, price: number, accountId) {
        const chainId = await this.web3.eth.getChainId();

        if (chainId === NETWORKS_INFO.bsc.chainId) {
            let allowance = await erc20Contract.methods.allowance(accountId, ContractAddress.MARKET_CONTRACT).call();

            if (allowance < price) {
                await erc20Contract.methods.approve(ContractAddress.MARKET_CONTRACT, price.toString()).send({from: accountId});
            }

            return await marketContract
                .methods
                .buyToken(saleId)
                .send({
                    from: this.account,
                })
        } else {
            alert('Wrong network');
        }
    }

    async removeNftFromSale(contract, saleId: number) {
        return contract
            .methods
            .cancelSale(saleId)
            .send({from: this.account})
    }

    async createNftSale(nftContract, marketContract, tokenId: number, price: number) {
        await this.approveTokens(nftContract);

        const approved = await nftContract
            .methods
            .isApprovedForAll(this.account, ContractAddress.MARKET_CONTRACT)
            .call();

        if (!approved) {
            alert('token not approved');
            return;
        }
        const value = await this.web3.utils.toWei(price.toString(), 'ether')

        return await marketContract
            .methods
            .createSale(tokenId, value)
            .send({from: this.account,});
    }

    async approveTokens(nftContract) {
        await nftContract
            .methods
            .setApprovalForAll(
                ContractAddress.MARKET_CONTRACT,
                true
            )
            .send({from: this.account,});
    }

    toWei(value): any {
        if (value) {
            return this.web3.utils.toWei(value.toString(), 'ether')
        }
    }

    fromWei(value) {
        if (value) {
            const res = this.web3.utils.fromWei(value.toString(), 'ether');
            return Number(res).toFixed(3)
        }
    }


}

export default Web3Store;
