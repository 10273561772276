import styled from "styled-components";
import roadmapCurve from "../../images/roadmap-curve.png"
import milestoneViolet from "../../images/milestone-violet.png"
import milestoneGrey from "../../images/milestone-grey.png"
import starBig from '../../images/star-big.svg'
import starSmall from '../../images/star-small.svg'
import milestoneVioletSmall from "../../images/milestone-violet-small.png"
import milestoneGreySmall from "../../images/milestone-grey-small.png"
import roadmapLine from "../../images/roadmap-line.png"

export const RoadmapStyled = styled.div`
  //width: 100%;
  //height: auto;
  //display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1288px;
  padding: 33px 40px;
  margin: 0 auto 29px;
  position: relative;
  //flex-direction: column;
  ///* .roadmap {
  //  width: 100vw;
  //  overflow-x: hidden;
  //} */

  &::before {
    position: absolute;
    content: '';
    top: 91px;
    left: 141px;
    background-image: url(${starSmall});
    background-repeat: no-repeat;
    background-position: top left;
    width: 54px;
    height: 46px;
  }
  
  &::after {
    position: absolute;
    content: '';
    bottom: 225px;
    right: 176px;
    background-image: url(${starBig});
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 74px;
    height: 74px;
  }
  
  h2 {
    font-size: 52px;
    line-height: 65px;
    font-weight: 800;
    color: #fff;
    text-transform: uppercase;
    font-family: "Dosis", sans-serif !important;
    margin-bottom: 89px;
    text-align: center;
  }
  //.center {
  //  margin-left: -20vw;
  //}
  .blue {
    background: linear-gradient(90deg, #3F3CD7 9.56%, #6D73F3 109.69%, #6171FE 193.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-shadow: 0px 4px 25px rgba(113, 130, 218, 0.5)
  }
  
  .roadmap__list {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    
    &::before {
      position: absolute;
      content: "";
      top: 34px;
      left: 28px;
      width: 100%;
      height: 100%;
      background-image: url('${roadmapCurve}');
      background-repeat: no-repeat;
  }
  
  .roadmap__item {
    margin-bottom: 86px;
    position: relative;
    
    &::before {
      position: absolute;
      content: "";
      top: 8px;
      left: -99px;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
    
    &:first-child {
      margin-left: 294px;
    }
    
    &:nth-child(2) {
      margin-left: 165px;
    }

    &:nth-child(3) {
      margin-left: 112px;
    }

    &:nth-child(4) {
      margin-left: 116px;
    }

    &:nth-child(5) {
      margin-left: 166px;
    }

    &:last-child {
      margin-left: 288px;
    }
  }
  
  .roadmap__item.roadmap__item--done::before {
    background-image: url('${milestoneViolet}');
  }
    
  .roadmap__item.roadmap__item--upcoming::before {
      background-image: url('${milestoneGrey}');
  }
    
  .roadmap__item span {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0.035em;
    color: #FFFFFF;
  }
  
  .roadmap__item p {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.035em;
    color: #9D9D9D;
    margin: 0;
  }
  //.robotic {
  //  font-family: "Press Start 2P", cursive !important;
  //  font-size: 42px !important;
  //  line-height: 50px !important;
  //  display: inline-block;
  //  margin-left: 5px;
  //  margin-bottom: -5px;
  //}
}

  .roadmap__planets {
    position: absolute;
    top: 275px;
    right: 27px;
  }

  .planets__list {
    display: flex;
    align-items: center;
  }
  
  .planets__item {
    display: flex;
    flex-direction: column;
  }
  
  .planets__item--first {
    margin-right: 28px;
  }
  
  .planets__item p {
    font-family: "Dosis", sans-serif;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.035em;
    color: #FFFFFF;
    text-transform: uppercase;
    margin: 0 0 29px 0;
  }
  
  .planets__item--first p {
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
  }
  
  .planets__item--second p {
    position: relative;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 40px;
  }

  .soon {
    position: absolute;
    content: '';
    right: 10px;
    top: 208px;
    width: 43px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(264.06deg, #C63B3B -68.99%, #D174E0 40.01%, #70BAFF 130.88%), linear-gradient(180deg, #C63B3B 0%, #D174E0 54.53%, #70BAFF 100%);
    border-radius: 28px;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.035em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  
  @media (max-width: 1439px) {
    &::before {
      width: 38px;
      height: 33px;
      background-size: contain;
      top: 96px;
      left: 155px;
    }
    
    &::after {
      width: 57px;
      height: 57px;
      background-size: contain;
      bottom: 139px;
      right: 166px;
    }
    
    h2 {
      font-size: 35px;
      line-height: 44px;
      margin-bottom: 78px;
    }
    
    .roadmap__list::before {
      width: 398px;
      height: 601px;
      background-size: contain;
      top: 24px;
      left: 11px;
    }

    .roadmap__list .roadmap__item {
      margin-bottom: 66px;
      
      &::before {
        top: 6px;
        left: -76px;
      }
      
      &:first-child {
        margin-left: 217px;
      }
      
      &:nth-child(2) {
        margin-left: 118px;
      }
      
      &:nth-child(3) {
        margin-left: 77px;
      }
      
      &:nth-child(4) {
        margin-left: 80px;
      }
      
      &:nth-child(5) {
        margin-left: 119px;
      }

      &:last-child {
        margin-left: 213px;
        margin-bottom: 0;
      }
    }
    
    .roadmap__list .roadmap__item span {
      font-size: 23px;
      line-height: 35px;
    }

    .roadmap__list .roadmap__item p {
      font-size: 12px;
      line-height: 19px;
    }
    
    .roadmap__item::before {
      width: 46px;
      height: 46px;
    }
    
    .roadmap__list .roadmap__item.roadmap__item--done::before {
      background-image: url(${milestoneVioletSmall});;
    }

    .roadmap__list .roadmap__item.roadmap__item--upcoming::before {
      background-image: url(${milestoneGreySmall});;
    }
    
    .planets__list {
      top: 219px;
      right: 47px;
    }
    
    .planets__item p {
      margin: 0 0 22px 0;
    }
    
    .planets__item--first {
      p {
        font-size: 19px;
        line-height: 28px;
      }
      
      .planets-img {
        width: 425px;
        height: 425px;
      } 
    }

    .planets__item--second {
      p {
        font-size: 12px;
        line-height: 19px;
        padding-right: 38px;
      }
      
      span {
        font-size: 9px;
        line-height: 14px;
        width: 33px;
        height: 14px;
        top: 163px;
      }
      
      .planets-img {
        width: 116px;
        height: 116px;
      }
    }
  }
  
  @media (max-width: 1023px) {
    padding: 23px 16px;
    margin-bottom: 0;
    
    &::before,
    &::after {
      display: none;
    }
    
    h2 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px;
    }
    
    .roadmap {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: max-content 1fr;
      grid-gap: 42px;
    }
    
    .roadmap__list::before {
      background-image: url(${roadmapLine});
      width: 2px;
      height: 450px;
      top: 18px;
      left: 23px;
    }
    
    .roadmap .roadmap__list .roadmap__item {
      margin-left: 81px;
      margin-bottom: 34px;
    }
    
    .roadmap__list .roadmap__item::before {
      left: -80px;
    }
    
    .planets__list {
      position: relative;
      grid-row: 1 / 2;
      display: block;
      top: unset;
      right: unset;
    }

    .planets__item {
      align-items: center;
    }
    
    .planets__item--first {
      margin-right: 0;
      
      p {
        font-size: 12px;
        line-height: 18px;
        margin: 0 0 12px 0;
      }
    }

    .planets__item--first .planets-img {
      width: 289px;
      height: 289px;
    }
    
    .planets__item--second {
      display: none;
    }
  }
  
  //#roadmap {
//  width: 100vw;
//  height: 600px;
//  float: left;
//  position: relative;
//  z-index: 1;
//  text-align: center;
//  /* overflow-x: scroll; */
//  /* margin-left: -20vw; */
//  .content-field {
//    width: 100%;
//    // width: calc(100% - 270px);
//    height: auto;
//    float: left;
//    margin-left: 270px;
//  }
//  @media (max-width: 1200px) {
//    overflow-x: hidden;
//    width: auto;
//  }
//}
//#roadmap::-webkit-scrollbar {
//    width: 15px;
//    margin: 15px;
//}
//
//#roadmap::-webkit-scrollbar-track {
//    background-color: transparent;
//}
//
//#roadmap::-webkit-scrollbar-thumb {
//    border: 5px solid rgba(0, 0, 0, 0);
//    background-clip: padding-box;
//    border-radius: 9999px;
//    background-color: rgba(255, 255, 255, 0.3);
//}
//.v13 {
//  top: 378px;
//  left: 0px;
//  height: 3px;
//  background: linear-gradient(270deg, #2F2F42 10.4%, #717185 100%);
//}
//
//.v14 {
//  top: 266px;
//  left: 0;
//  height: 3px;
//  background: #262631;
//}
//
//.v15 {
//  top: 320px;
//  left: 0;
//  height: 3px;
//  border: 2px solid;
//  border-image-source: linear-gradient(270deg, #2F2F42 43.4%, #717185 100%);
//}
//
//.dot {
//  width: 36px;
//  height: 36px;
//  border-radius: 50%;
//  background: #3F3CD7;
//  border: 4px solid #2F2F42;
//  position: absolute;
//  z-index: 5;
//  @media (max-width:1800px) {
//    margin-left: -70px;
//  }
//  @media (max-width:1600px) {
//    margin-left: -150px;
//  }
//  @media (max-width:1500px) {
//    margin-left: -250px;
//  }
//}
//
//.dot1 {
//  left: 350px;
//  top: 303px;
//}
//
//.dot2 {
//  left: 650px;
//  top: 303px;
//}
//
//.dot3 {
//  left: 950px;
//  top: 303px;
//}
//
//.dot4 {
//  left: 1250px;
//  top: 303px;
//}
//
//.dot5 {
//  left: 1502px;
//  top: 303px;
//}
//
//.c-line {
//  height: 3px;
//  width: 60px;
//  background: linear-gradient(270deg, #2F2F42 43.4%, #717185 100%);
//  position: absolute;
//  transform: rotate(-90deg);
//  @media (max-width:1800px) {
//    margin-left: -70px;
//  }
//  @media (max-width:1600px) {
//    margin-left: -150px;
//  }
//  @media (max-width:1500px) {
//    margin-left: -250px;
//  }
//}
//
//.line1 {
//  left: 338px;
//  top: 290px;
//}
//
//.line2 {
//  left: 638px;
//  top: 350px;
//  transform: rotate(90deg);
//}
//
//.line3 {
//  left: 938px;
//  top: 290px;
//}
//
//.line4 {
//  left: 1238px;
//  top: 350px;
//  transform: rotate(90deg);
//}
//
//.line5 {
//  left: 1490px;
//  top: 290px;
//}
//
//.box {
//  width: 175px;
//  height: 145px;
//  position: absolute;
//  background: linear-gradient(90deg, #2E2E3C 33.58%, #23232F 85.82%);
///* Shadow elements */
//  @media (max-width:1800px) {
//    margin-left: -70px;
//  }
//  @media (max-width:1600px) {
//    margin-left: -150px;
//  }
//  @media (max-width:1500px) {
//    margin-left: -250px;
//  }
//  box-shadow: 0px 4px 45px rgba(0, 0, 0, 0.25);
//  border-radius: 30px;
//  text-align: left;
//  font-weight: bold;
//  .roadmap-title {
//    font-family: Dosis;
//    font-style: normal;
//    font-weight: bold;
//    font-size: 20px;
//    text-transform: uppercase;
//    color: #FFFFFF;
//    display: block;
//    text-align: center;
//    width: 100%;
//    margin-top: 10px;
//    
//  }
//
//  ul {
//    width: 100%;
//    float: left;
//    margin-top: 10px;
//    margin-left: 40px;
//    color: #fff;
//
//    li {
//      margin-top: 5px;
//      width: 145px;
//    }
//  }
//
//  ul li::before {
//    content: "\\2022";
//    color: #3F3CD7;
//    font-weight: bold;
//    display: inline-block;
//    width: 1em;
//    margin-left: -1em;
//  }
//}
//
//.box1 {
//  left: 280px;
//  top: 120px;
//}
//
//.box2 {
//  left: 580px;
//  top: 380px;
//}
//
//.box3 {
//  left: 880px;
//  top: 120px;
//}
//
//.box4 {
//  left: 1180px;
//  top: 380px;
//  /* height: 160px; */
//}
//
//.box5 {
//  left: 1430px;
//  top: 120px;
//}
//
//.horizontal {
//  height: 1px;
//  width: 100vw;
//  position: absolute;
//  left: 0;
//  background: #414145;
//}
//
//@media only screen and (max-width: 1200px) {
//  .box1, .box2, .box3, .box4, .box5 {
//    position: relative;
//    float: left;
//    top: inherit;
//    left: inherit;
//    margin-top: 40px;
//    width: 300px;
//  }
//  
//.horizontal {
// display:none
//}
//  .content-field {
//    width: 100% !important;
//    margin-left: 0 !important;
//  }
//  .box1, .box3, .box5 {
//    margin-left: 40px;
//  }
//
//  .box2, .box4 {
//    margin-left: 20px;
//  }
//
//  .dot1, .dot2, .dot3, .dot4, .line5 {
//    display: none;
//  }
//
//  .line1, .line2, .line3, .line4, .line5 {
//    display: none;
//  }
//
//  .center {
//    width: 700px;
//    height: auto;
//    margin: 0 auto;
//  }
//}
//
//@media only screen and (max-width: 1023px) {
//  #roadmap .content-field {
//    width: 100% !important;
//    margin-left: 0 !important;
//  }
//
//  h2 {
//    width: 100%;
//    margin-left: 40px;
//  }
//}
//
//@media only screen and (max-width: 800px) {
//  #roadmap {
//    height: auto;
//    min-height: 550px;
//    margin-bottom: 80px;
//    margin-top: 20px;
//  }
//
//  h2 {
//    text-align: center;
//    margin-left: 0;
//  }
//
//  .box1, .box2, .box3, .box4, .box5 {
//    width: 40%;
//    margin-left: 30%;
//  }
`;
