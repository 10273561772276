import React from 'react';
import {EmptyMarketplaceStyled} from "./EmptyMarketplace.styled";
import ship3 from '../../images/ship-1-mobile.png'
import AnimatedImg from "../AnimatedImg/AnimatedImg";

const EmptyMarketplace: React.FC = ({setOpacity, title}: any) => {
    return (
        <EmptyMarketplaceStyled>
            <div className="explore">
                <div className="left">
                    <h2><span className="blue">{title}</span>coming
                        <br/>

                        soon
                    </h2>
                    <p>
                        First wave of founders collectible NFTs in June,
                        raising $3 million. And now it will create NFT collectibles
                        in partnership with the NRG Esports to tap into the competitive
                        gaming market (which NFTs and blockchain haven’t turned to yet)
                        and bolster fan engagement.
                    </p>
                    <div className="buttons">
                        <button className="button bg-blue">{"Watch Trailer".toUpperCase()}</button>
                        {/*todo add link to roadmap*/}
                        <a className="button bg-pink" href="#">{"Roadmap 2022 Q4".toUpperCase()}</a>
                    </div>
                </div>
                <img className='ship-img' src={ship3}  onLoad={()=>{setOpacity(true)}}  />
            </div>
        </EmptyMarketplaceStyled>
    );
};

export default EmptyMarketplace;
