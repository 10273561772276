import React, {useEffect} from 'react';
import {Web3Store} from "../../stores/Web3Store";
import {DataStore} from "../../stores/DataStore";
import {useInjection} from "inversify-react";
import {useParams} from "react-router";
import {SelectedProductStyled} from "./SelectedProduct.styled";
import Navigation from "../Navigation/Navigation";
import ConnectWalletBtn from "../ConnectWalletBtn/ConnectWalletBtn";
import {Link} from "react-router-dom";
import SeasonLabel from "../UIcomponents/SeasonLabel/SeasonLabel";
import Rarity from "../UIcomponents/Rarity/Rarity";
import arrowImg from "../../images/svg/arrow-right.svg";
import LoadWrapper from "../LoadWrapper/LoadWrapper";
import {observer} from "mobx-react";
import ProductsItem, {buyTokenHandler, cancelSell, sellTokenHandler} from "../ProductsItem/ProductsItem";
import {AppStore} from "../../stores/AppStore";
import {WalletStore} from "../../stores";
import {PRICE_DECIMALS} from "../../const";
import {NftButton} from '../NftButton/NftButton';
import {formatDistance} from 'date-fns';


const SelectedProduct: React.FC = observer(() => {
    const dataStore = useInjection(DataStore);
    const web3Store = useInjection(Web3Store);
    const appStore = useInjection(AppStore);
    const walletStore = useInjection(WalletStore);
    const params: { id: string } = useParams();
    const id: number = Number(params.id);

    useEffect(() => {
        dataStore.getActiveToken(id);
    }, [])

    useEffect(() => {
        dataStore?.getMyTokens(dataStore?.activeToken?.Collection?.owner, true);
    }, [dataStore?.activeToken?.Collection?.owner, dataStore?.isActiveTokenLoaded, dataStore.activeToken, window.location])

    const nft = dataStore?.activeToken;
    const account = web3Store?.account;
    const myNft = dataStore?.myNfts;
    const history = nft?.history.data;

    const backPath = () => {
        if (window.location.pathname.includes('marketplace')) {
            return '/marketplace'
        } else {
            return '/mynft'
        }
    }


    // @ts-ignore
    return (
        <SelectedProductStyled>
            <LoadWrapper isDataLoaded={dataStore?.isActiveTokenLoaded}>
                <div className="main-content">
                    <Navigation/>
                    <ConnectWalletBtn/>
                    <div className="container">
                        <section className="nft">
                            <Link className="back-btn" to={backPath()}>
                                <img src={arrowImg} alt=""/>
                                Back
                            </Link>
                            <div className="nft-name">
                                <p className="name">{nft?.name}</p>
                                <SeasonLabel season={nft?.season || 'Season 1'}/>
                            </div>
                            <img
                                className="icon-img"
                                src={nft?.image}
                                alt=""
                                width={400}
                                height={400}
                            />
                            <div className="characteristics">
                                <div className="price">
                                    <p className="price-title characteristics-title">Price</p>
                                    <p className="price-number characteristics-number-field">{nft?.price ? nft?.price / PRICE_DECIMALS: 0} $BTH</p>
                                </div>
                                <div className="quantity">
                                    <p className="quantity-title characteristics-title">Quantity</p>
                                    <p className="quantity-number characteristics-number-field">
                                        <span className="product-amount">{nft?.Collection?.amount}</span>
                                        &nbsp;/&nbsp;
                                        {/*@ts-ignore*/}
                                        <span className="product-out-of">{nft?.Collection?.allAmount || 50}</span>
                                    </p>
                                </div>
                                <div className="replicas">
                                    <p className="price-title characteristics-title">Replicas</p>
                                    {/*@ts-ignore*/}
                                    <p className="price-number characteristics-number-field">{nft?.Collection.replicas}</p>
                                </div>
                                <Rarity rarity={nft?.rarity + 1}/>
                            </div>
                            {
                                account === nft?.Collection.owner
                                    ? !nft?.Collection.token[0].saleId
                                        ? <NftButton
                                            className="sell-button"
                                            disabled={!walletStore?.connected}
                                            text="Approve token"
                                            textClassName="sell-button-text"
                                            onClick={async () =>
                                                sellTokenHandler(web3Store, dataStore?.activeToken?.Collection?.token[0]?.tokenId, appStore)
                                            }
                                        />
                                        : <NftButton
                                            className="sell-button"
                                            disabled={!walletStore?.connected}
                                            text="Remove from sale"
                                            textClassName="sell-button-text"
                                            onClick={async () =>
                                                cancelSell(web3Store, dataStore?.activeToken?.Collection?.token[0]?.saleId, appStore)
                                            }
                                        />
                                    : <NftButton
                                        className="sell-button"
                                        onClick={async () =>
                                            buyTokenHandler(web3Store, dataStore?.activeToken?.Collection?.token[0]?.saleId, nft?.price, appStore, account)
                                        }
                                        text="Buy now"
                                        textClassName="sell-button-text"
                                        disabled={!walletStore.connected}
                                    />
                            }
                            <div className="owner">
                                <p className="owner-title">Owner:</p>
                                &nbsp;
                                <p className="owner-number">{nft?.Collection?.owner}</p>
                            </div>
                            <div className="description">
                                <p className="description-title">Description</p>
                                <p className="description-text">
                                    {nft?.description}
                                </p>
                            </div>
                        </section>
                        <section className="more-nft">
                            <h2 className="h2 more-nft">More nfts by {nft?.Collection?.owner}</h2>
                            <LoadWrapper isDataLoaded={dataStore?.isMyNftsLoaded}>
                                <ul className="more-nft-list">
                                    {
                                        // @ts-ignore
                                        myNft?.filter((item) => item.token[0].tokenId !== nft?.tokenId).filter((item) => item.token[0].saleId).slice(0, 4).map((item, index) => {
                                            return (
                                                <ProductsItem
                                                    product={item}
                                                    key={index}
                                                />
                                            )
                                        })
                                    }
                                </ul>
                            </LoadWrapper>
                        </section>
                        <section className="history">
                            <h2 className="h2 sale-history">Sale history</h2>
                            <ul className="sale-history-list">
                                {
                                    // @ts-ignore
                                    history?.length !== 0
                                        ? history?.slice(0, 10).map((item, index) => {
                                            return (
                                                <li key={index} className="sale-history-item">
                                                    <div className="buyer item-title">
                                                        <p className="buyer-label label">Buyer</p>
                                                        <p className="buyer-content content">
                                                            {item.buyer.slice(0, 12)}...
                                                        </p>
                                                    </div>
                                                    <div className="seller item-title">
                                                        <p className="seller-label label">Seller</p>
                                                        <p className="seller-content content">{
                                                            item.seller.slice(0, 12)}...
                                                        </p>
                                                    </div>
                                                    <div className="sale-data">
                                                        <p className="sale-price">{item.price/PRICE_DECIMALS} $BTH</p>
                                                        <p className="sale-date">
                                                            {
                                                                formatDistance(
                                                                    new Date(item.date),
                                                                    new Date(),
                                                                    {addSuffix: true}
                                                                )
                                                            }
                                                        </p>
                                                    </div>
                                                </li>
                                            )
                                        })
                                        : <p>No sale history</p>
                                }
                            </ul>
                        </section>
                    </div>
                </div>
            </LoadWrapper>
        </SelectedProductStyled>
    );
});

export default SelectedProduct;
