import styled from "styled-components";

export const SeasonLabelStyled = styled.p`
  font-size: 16px;
  color: ${({theme}) => theme.colors.primaryText};
  border-radius: 5px;
  padding: 8px 20px;
  display: inline-block;
  max-height: 34px;

  &.season-1 {
    background: linear-gradient(99.11deg, #4BDE88 -31.56%, #82FC63 215.5%);
  }

  &.season-2 {
    background: linear-gradient(109.12deg, #FD2929 -14.05%, #FE66C1 134.06%);
  }

  &.season-3 {
    background: linear-gradient(90deg, #3F3CD7 9.56%, #6D73F3 109.69%, #6171FE 193.17%);
  }

  &.season-4 {
    background: linear-gradient(100.01deg, #07E9C0 11.47%, #07B2E9 143.84%);
  }
`